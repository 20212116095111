import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SendEmailForm.css'; // Assume you have a CSS file for styles


function SendEmailForm({ clientEmail }) {
    const [emailData, setEmailData] = useState({
        to: clientEmail || '',
        cc: '',
        subject: '',
        text: ''
    });
    const [attachments, setAttachments] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const api = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        setEmailData(prevData => ({ ...prevData, to: clientEmail }));
    }, [clientEmail]);

    const handleChange = (event) => {
        setEmailData({
            ...emailData,
            [event.target.name]: event.target.value
        });
    };

    const handleFileChange = (event) => {
        setAttachments(event.target.files);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSending(true);
        const formData = new FormData();
        Object.keys(emailData).forEach(key => {
            formData.append(key, emailData[key]);
        });
        Array.from(attachments).forEach(file => {
            formData.append('attachments', file);
        });

        try {
            const response = await axios.post( api + 'send-custom-email', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setResponseMessage({ type: 'success', text: 'Email sent successfully: ' + response.data.info });
        } catch (error) {
            setResponseMessage({ type: 'error', text: 'Failed to send email: ' + (error.response?.data.message || error.message) });
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className="send-email-form">
            <h2>Send Email</h2>
            <form onSubmit={handleSubmit} className="email-form">
                <div className="form-group">
                    <label>To:</label>
                    <input
                        type="email"
                        name="to"
                        value={emailData.to}
                        onChange={handleChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>CC:</label>
                    <input
                        type="email"
                        name="cc"
                        value={emailData.cc}
                        onChange={handleChange}
                        placeholder="CC recipients (comma separated)"
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Subject:</label>
                    <input
                        type="text"
                        name="subject"
                        value={emailData.subject}
                        onChange={handleChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Text:</label>
                    <textarea
                        name="text"
                        value={emailData.text}
                        onChange={handleChange}
                        required
                        className="form-control"
                        rows="4"
                    />
                </div>
                <div className="form-group">
                    <label>Attachments:</label>
                    <input
                        type="file"
                        name="attachments"
                        onChange={handleFileChange}
                        multiple
                        className="form-control-file"
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSending}>
                    {isSending ? 'Sending...' : 'Send Email'}
                </button>
            </form>
            {responseMessage.text && <p className={`response-message ${responseMessage.type}`}>{responseMessage.text}</p>}
        </div>
    );
}

export default SendEmailForm;
