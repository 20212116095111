import React, { useState } from 'react';
import axios from 'axios';
import './EmailSection.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';


const EmailSection = ({ clientData, conversations, searchQuery, setSearchQuery,accessToken }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const backend = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const toggleMinimize = () => setIsMinimized(!isMinimized);

  const handleReplyChange = (e) => {
    setReplyContent(e.target.value);
  };

  const handleSendReply = async (threadId, conversation) => {
    try {
      const subject = conversation.payload.headers.find(header => header.name === 'Subject');
      const formData = new FormData();
      formData.append('to', clientData.emails);
      formData.append('subject', `Re: ${subject ? subject.value : 'No Subject'}`);
      formData.append('text', replyContent);
      formData.append('threadId', threadId);
      selectedAttachments.forEach(file => {
        formData.append('attachments', file);
      });

      const response = await axios.post(`${backend}send-email-frontend`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      console.log('Email sent successfully:', response.data);
      alert('Email sent successfully!');
      setReplyContent('');
      setSelectedAttachments([]);
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email. Please try again.');
    }
  };

const handleDownloadAttachment = async (part, messageId, accessToken) => {
  console.log("Attempting to download part:", part);

  // Check for attachment ID existence
  if (!part.body || !part.body.attachmentId) {
    alert('No attachment data available to download.');
    console.error('No attachment data available:', part);
    return; // Exit the function if there is no attachment ID
  }

  const attachmentUrl = `https://www.googleapis.com/gmail/v1/users/me/messages/${messageId}/attachments/${part.body.attachmentId}?access_token=${accessToken}`;

  try {
    const response = await fetch(attachmentUrl);
    const attachmentData = await response.json();

    if (!attachmentData || !attachmentData.data) {
      throw new Error('Failed to fetch attachment data.');
    }

    let base64String = attachmentData.data.replace(/-/g, '+').replace(/_/g, '/');
    while (base64String.length % 4) {
      base64String += '=';
    }

    const decodedBytes = atob(base64String);
    const byteNumbers = new Array(decodedBytes.length);
    for (let i = 0; i < decodedBytes.length; i++) {
      byteNumbers[i] = decodedBytes.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: part.mimeType });
    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary anchor to simulate download
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = part.filename || 'downloaded_attachment';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Failed to download the attachment:", error);
    alert('Failed to download attachment. Please try again.');
  }
};

const extractEmailContent = (payload) => {
  let textContent = '';

  // Function to decode the base64 encoded string
  const decodeBase64 = (data) => {
    try {
      return atob(data.replace(/-/g, '+').replace(/_/g, '/'));
    } catch (e) {
      console.error('Failed to decode base64 string', e);
      return '';
    }
  };

  // Recursively extract text from parts
  const getTextFromPart = (part) => {
    if (part.parts) {
      // This part has sub-parts, recurse into each
      part.parts.forEach(getTextFromPart);
    } else if (part.mimeType === 'text/plain' && part.body && part.body.data) {
      // Extract text/plain content
      textContent += decodeBase64(part.body.data) + '\n'; // Append with a new line
    }
  };

  if (payload.parts) {
    // Email with multiple parts
    payload.parts.forEach(getTextFromPart);
  } else if (payload.body && payload.body.data && payload.mimeType === 'text/plain') {
    // Email with a direct body and no parts
    textContent += decodeBase64(payload.body.data) + '\n';
  }

  return textContent.trim();
};



 const filteredConversations = conversations && Array.isArray(conversations) ? Object.entries(
  conversations.reduce((acc, conversation) => {
    if (conversation.isDraft) {
      return acc; // Skip drafts
    }
    const threadId = conversation.threadId || conversation.id;
    if (!acc[threadId]) {
      acc[threadId] = [];
    }
    acc[threadId].push(conversation);
    return acc;
  }, {})
)
.filter(([threadId, threadConversations]) => {
  const latestConversation = threadConversations[threadConversations.length - 1];
  const subject = latestConversation?.payload?.headers?.find(header => header.name === 'Subject')?.value || 'No Subject';
  const from = latestConversation?.payload?.headers?.find(header => header.name === 'From')?.value || '';
  const date = latestConversation?.payload?.headers?.find(header => header.name === 'Date')?.value || '';
  
  // Safe check for parts and data

  const emailContent = extractEmailContent(latestConversation?.payload)

  // console.log(emailContent)

  const searchTerms = searchQuery.toLowerCase().split(' ');
  return searchTerms.every(term => (
    subject.toLowerCase().includes(term) ||
    from.toLowerCase().includes(term) ||
    date.toLowerCase().includes(term) ||
    emailContent.toLowerCase().includes(term)
  ));
})
.sort(([, threadConversationsA], [, threadConversationsB]) => (
  new Date(threadConversationsB[threadConversationsB.length - 1]?.payload?.headers?.find(header => header.name === 'Date')?.value || 0) -
  new Date(threadConversationsA[threadConversationsA.length - 1]?.payload?.headers?.find(header => header.name === 'Date')?.value || 0)
)) : [];


const cleanEmailContent = (content) => {
    // Split the content into lines
    const lines = content.split('\n');

    // Filter out lines that start with '<' or start with 'On'
    const filteredLines = lines.filter(line => {
        const trimmedLine = line.trim();
        return !(trimmedLine.startsWith('>') || trimmedLine.startsWith('On ') || trimmedLine==="wrote:" || trimmedLine.startsWith("Den ") || trimmedLine.startsWith("heimwood@heimwood.dk>:"));
    });

    // Join the remaining lines back into a single string
    return filteredLines.join('\n');
};

function decodeEmailContent(encodedString, charset = 'UTF-8') {
    try {
        const binaryStr = atob(encodedString.replace(/-/g, '+').replace(/_/g, '/')); // Decode Base64
        const bytes = new Uint8Array(binaryStr.length);
        for (let i = 0; i < binaryStr.length; i++) {
            bytes[i] = binaryStr.charCodeAt(i);
        }
        const decoder = new TextDecoder(charset);
        return decoder.decode(bytes);
    } catch (error) {
        console.error("Decoding error:", error);
        return "Decoding failed"; // or handle the error appropriately
    }
}


  return (
    <div className="email-section">
      <button className="minimize-button" onClick={toggleMinimize}>
        {isMinimized ? 'Expand' : 'Minimize'}
      </button>
      {!isMinimized && (
        <>
          <h1 className="client-name">{clientData.name}</h1>
          <h2 className="client-number">{clientData.client_number}</h2>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search emails..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {conversations.length === 0 ? (
            <p>Loading conversations...</p>
          ) : (
            <div>
              <h2>Email Conversations</h2>
              {console.log(filteredConversations)}
              {filteredConversations.map(([threadId, threadConversations]) => {
                const sortedConversations = threadConversations.sort((a, b) => {
                  const dateA = new Date(a.payload.headers.find(header => header.name === 'Date').value);
                  const dateB = new Date(b.payload.headers.find(header => header.name === 'Date').value);
                  return dateA - dateB;
                });
return (
  <div key={threadId} className="email-thread">
    <div className="thread-header">
      <p>Subject: {sortedConversations[0].payload.headers.find(header => header.name === 'Subject')?.value || 'No Subject'}</p>
    </div>
    <div className="thread-conversations">
      {sortedConversations.map((conversation, conversationIndex) => {
        const from = conversation.payload.headers.find(header => header.name === 'From').value;
        const date = conversation.payload.headers.find(header => header.name === 'Date').value;
        const isFromClient = clientData.emails.split(',').some(email => from.includes(email.trim()));

        return (
          <div key={conversation.id} className="thread-conversation">
            <div className="message-header">
              <p>{isFromClient ? 'Client' : 'Me'} - {date}</p>
            </div>
            <div className="message-content">
              {conversation.payload.parts ? (
                conversation.payload.parts.map((part, partIndex) => {
                  let elements = [];
                  
                  if (part.mimeType === 'text/plain' && part.body && part.body.data) {
                    const decodedContent = decodeEmailContent(part.body.data);
                    const cleanedContent = cleanEmailContent(decodedContent);
                    elements.push(<pre key={`text-${partIndex}`}>{cleanedContent}</pre>);
                  }
                  
                  if (part.filename) {
                    elements.push(
                      <p key={`attachment-${partIndex}`}>
                        Attachment: <button onClick={() => handleDownloadAttachment(part, conversation.id, accessToken)}>
                          Download {part.filename}
                        </button>
                      </p>
                    );
                  }

                  // Handling multipart/alternative
                  if (part.mimeType === 'multipart/alternative' && part.parts) {
                    part.parts.forEach((nestedPart, nestedIndex) => {
                      if (nestedPart.mimeType === 'text/plain' && nestedPart.body && nestedPart.body.data) {
                        const nestedDecodedContent = decodeEmailContent(nestedPart.body.data);
                        const nestedCleanedContent = cleanEmailContent(nestedDecodedContent);
                        elements.push(<pre key={`nested-text-${partIndex}-${nestedIndex}`}>{nestedCleanedContent}</pre>);
                      }
                    });
                  }

                  return elements.length > 0 ? elements : null;
                })
              ) : conversation.payload.body && conversation.payload.mimeType === 'text/plain' ? (
                <pre>{cleanEmailContent(decodeEmailContent(conversation.payload.body.data))}</pre>
              ) : null}
            </div>
            {conversationIndex !== sortedConversations.length - 1 && <hr />}
          </div>
        );
      })}
    </div>
    <div className="reply-section">
      <textarea
        placeholder="Type your reply here..."
        value={replyContent}
        onChange={handleReplyChange}
      />
      <input
        type="file"
        multiple
        onChange={(e) => setSelectedAttachments(Array.from(e.target.files))}
      />
      <button onClick={() => handleSendReply(threadId, sortedConversations[sortedConversations.length - 1])}>Send Reply</button>
    </div>
  </div>
);

                })}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

export default EmailSection;
