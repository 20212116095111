import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input, List,Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NewEmails = () => {
  const [hoursAgo, setHoursAgo] = useState(24);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
   const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      fetchEmailsFromLast24Hours();
    }
  }, [accessToken, hoursAgo]);

  const fetchEmailsFromLast24Hours = async () => {
    console.log("fetcham")
    const millisecondsPerHour = 3600000;
    const date = new Date(new Date().getTime() - hoursAgo * millisecondsPerHour);
    const query = `after:${Math.floor(date.getTime() / 1000)}`;

    try {
      const response = await axios.get('https://gmail.googleapis.com/gmail/v1/users/me/messages', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          q: query,
        },
      });

      const messages = response.data.messages || [];

      const conversationPromises = messages.map(async (message) => {
        const messageResponse = await axios.get(
          `https://gmail.googleapis.com/gmail/v1/users/me/messages/${message.id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return messageResponse.data.payload.headers.find(header => header.name === 'From')?.value;
      });

      const emailFromAddresses = await Promise.all(conversationPromises);
      setEmailAddresses([...new Set(emailFromAddresses)]); // Using a Set to remove duplicates
    } catch (error) {
      console.error('Error fetching emails from the last 24 hours:', error);
      if (error.response && error.response.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          renewAccessToken(refreshToken);
        }
      }
    }
  };

  const renewAccessToken = async (refreshToken) => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        client_id: '539619047487-5l1ove66js3g59fitghe0trv180jpmoj.apps.googleusercontent.com',
        client_secret: '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',
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      });

      const { access_token } = response.data;
      localStorage.setItem('accessToken', access_token);
      setAccessToken(access_token); // Update state to trigger useEffect
    } catch (error) {
      console.error('Error renewing access token:', error);
    }
  };

  return (
    <div>
      <Button onClick={() => navigate('/')} style={{ marginRight: 8 }}>Client Table</Button>
            <Button onClick={() => navigate('/fa-et-tilbud-leads')} style={{ marginRight: 8 }}>Fa et Tilbud Leads</Button>
            <Button onClick={() => navigate('/quick-leads')} style={{ marginRight: 8 }}>Quick Config Leads</Button>
            <Button onClick={() => navigate('/booking-leads')} style={{ marginRight: 8 }}>Booking Leads</Button>
            <Button onClick={() => navigate('/booking-pictures')}>Booking Pictures</Button>
      <h1>Emails from Senders in the Past {hoursAgo} Hours</h1>
      <Input
        type="number"
        min="1"
        max="72"
        value={hoursAgo}
        onChange={e => setHoursAgo(e.target.value)}
        placeholder="Hours ago"
        style={{ width: 200, marginBottom: 20 }}
      />
      <List
        bordered
        dataSource={emailAddresses}
        renderItem={email => (
          <List.Item>
            {email}
          </List.Item>
        )}
      />
    </div>
  );
};

export default NewEmails;
