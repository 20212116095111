import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaTicketAlt, FaComments, FaTimes, FaFile, FaEdit, FaSave } from 'react-icons/fa';

// Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Title = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 30px;
`;

const TicketList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TicketItem = styled.li`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TicketField = styled.p`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatButton = styled(Link)`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const Label = styled.span`
  font-weight: bold;
  color: #555;
`;

const Value = styled.span`
  color: #333;
`;

const Input = styled.input`
  width: 70%;
  padding: 5px;
`;

const EditButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
`;

const SaveButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  text-align: center;
  margin-bottom: 20px;
`;

const FileLink = styled.a`
  color: #007bff;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const FilterContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Select = styled.select`
  padding: 8px 12px;
  font-size: 16px;
  margin-left: 10px;
`;

// Styled Modal for Alerts
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

// Ticket Component
const Tickets = ({ email }) => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');
  const [editField, setEditField] = useState({});
  const [statusFilter, setStatusFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const api = process.env.REACT_APP_BACKEND_URL;

  // Fetch contracts and their associated tickets
  const fetchContractsAndTickets = async () => {
    try {
      const contractsResponse = await axios.get(`${api}/usercontract/${email}`);
      const contracts = contractsResponse.data;

      const ticketPromises = contracts.map(async (contract) => {
        const ticketsResponse = await axios.get(`${api}/tickets/get-contract-tickets/${contract._id}`);
        return ticketsResponse.data;
      });

      const allTickets = (await Promise.all(ticketPromises)).flat();
      setTickets(allTickets);
    } catch (error) {
      setError('Error fetching contracts and tickets');
      console.error('Error fetching contracts and tickets:', error);
    }
  };

  useEffect(() => {
    if (email) {
      fetchContractsAndTickets();
    }
  }, [email]);

  const handleEdit = (ticketId, field) => {
    setEditField({ ticketId, field });
  };

  const handleFieldChange = (ticketId, field, value) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) =>
        ticket._id === ticketId ? { ...ticket, [field]: value } : ticket
      )
    );
  };

  const handleSave = async (ticketId, field) => {
    const ticketToUpdate = tickets.find((ticket) => ticket._id === ticketId);
    try {
      await axios.put(`${api}/tickets/update/${ticketId}`, { [field]: ticketToUpdate[field] });
      setEditField({});
      setModalMessage('Ticket updated successfully');
      setShowModal(true);
    } catch (error) {
      console.error('Error updating ticket:', error);
      setModalMessage('Error updating ticket');
      setShowModal(true);
    }
  };

  const filteredTickets = statusFilter
    ? tickets.filter(ticket => ticket.status === statusFilter)
    : tickets;

  return (
    <Container>
      <Title>Tickets</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {/* Filter Dropdown */}
      <FilterContainer>
        <Label>Filter by Status:</Label>
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="">All</option>
          <option value="Working on it">Working on it</option>
          <option value="Stuck">Stuck</option>
          <option value="Closed">Closed</option>
        </Select>
      </FilterContainer>

      <TicketList>
        {filteredTickets.map((ticket) => (
          <TicketItem key={ticket._id}>
            <TicketField>
              <Label>Title:</Label>
              {editField.ticketId === ticket._id && editField.field === 'title' ? (
                <>
                  <Input
                    value={ticket.title}
                    onChange={(e) => handleFieldChange(ticket._id, 'title', e.target.value)}
                  />
                  <SaveButton onClick={() => handleSave(ticket._id, 'title')}>
                    <FaSave /> Save
                  </SaveButton>
                </>
              ) : (
                <>
                  <Value>{ticket.title}</Value>
                  <EditButton onClick={() => handleEdit(ticket._id, 'title')}>
                    <FaEdit /> Edit
                  </EditButton>
                </>
              )}
            </TicketField>

            <TicketField>
              <Label>Text:</Label>
              {editField.ticketId === ticket._id && editField.field === 'text' ? (
                <>
                  <Input
                    value={ticket.text}
                    onChange={(e) => handleFieldChange(ticket._id, 'text', e.target.value)}
                  />
                  <SaveButton onClick={() => handleSave(ticket._id, 'text')}>
                    <FaSave /> Save
                  </SaveButton>
                </>
              ) : (
                <>
                  <Value>{ticket.text}</Value>
                  <EditButton onClick={() => handleEdit(ticket._id, 'text')}>
                    <FaEdit /> Edit
                  </EditButton>
                </>
              )}
            </TicketField>

            <TicketField>
              <Label>Urgency:</Label>
              {editField.ticketId === ticket._id && editField.field === 'urgency' ? (
                <>
                  <Input
                    value={ticket.urgency}
                    onChange={(e) => handleFieldChange(ticket._id, 'urgency', e.target.value)}
                  />
                  <SaveButton onClick={() => handleSave(ticket._id, 'urgency')}>
                    <FaSave /> Save
                  </SaveButton>
                </>
              ) : (
                <>
                  <Value>{ticket.urgency}</Value>
                  <EditButton onClick={() => handleEdit(ticket._id, 'urgency')}>
                    <FaEdit /> Edit
                  </EditButton>
                </>
              )}
            </TicketField>

            <TicketField>
              <Label>Status:</Label>
              {editField.ticketId === ticket._id && editField.field === 'status' ? (
                <>
                  <select
                    value={ticket.status}
                    onChange={(e) => handleFieldChange(ticket._id, 'status', e.target.value)}
                  >
                    <option value="Working on it">Working on it</option>
                    <option value="Stuck">Stuck</option>
                    <option value="Closed">Closed</option>
                  </select>
                  <SaveButton onClick={() => handleSave(ticket._id, 'status')}>
                    <FaSave /> Save
                  </SaveButton>
                </>
              ) : (
                <>
                  <Value>{ticket.status}</Value>
                  <EditButton onClick={() => handleEdit(ticket._id, 'status')}>
                    <FaEdit /> Edit
                  </EditButton>
                </>
              )}
            </TicketField>

            <TicketField>
              <Label>Created At:</Label>
              <Value>{new Date(ticket.createdAt).toLocaleString()}</Value>
            </TicketField>

            <TicketField>
              <Label>Files:</Label>
              <div>
                {ticket.files && ticket.files.length > 0 ? (
                  ticket.files.map((file, index) => (
                    <FileLink key={index} href={file} target="_blank" rel="noopener noreferrer">
                      <FaFile className="mr-2" />
                      File {index + 1}
                    </FileLink>
                  ))
                ) : (
                  <span>No files attached</span>
                )}
              </div>
            </TicketField>
          </TicketItem>
        ))}
      </TicketList>

      {showModal && (
        <ModalOverlay>
          <Modal>
            <p>{modalMessage}</p>
            <ModalButton onClick={() => setShowModal(false)}>OK</ModalButton>
          </Modal>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default Tickets;
