import React from 'react';

const NotesSection = ({ clientData, newNote, setNewNote, addNote, deleteNote }) => {
  return (
    <div className="notes-section">
      <h2>Notes</h2>
      {clientData.logs.map((log, index) => (
        <div key={index} className="note">
          <p>{log}</p>
          <button className="delete-note" onClick={() => deleteNote(index)}>
            Delete
          </button>
        </div>
      ))}
      <div className="add-note">
        <input
          type="text"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Enter a new note"
        />
        <button onClick={addNote}>Add Note</button>
      </div>
    </div>
  );
};

export default NotesSection;