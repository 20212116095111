import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './styles.css';
import EmailSection from './EmailSection';
import Sidebar from './Sidebar';
import NotesSection from './NotesSection';
import AttachmentSection from './AttachmentSection';
import SendEmailForm from "./SendEmailForm";
import PdfUploadComponent from './PdfUploadComponent';
import Installations from './Installations';
import Tickets from './Tickets';
import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

const ClientDetailsComponent = () => {
  const { clientId } = useParams();
  const [clientData, setClientData] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [accessToken, setAccessToken] = useState(''); // AccessToken state
  const [refreshToken, setRefreshToken] = useState('');
  const [selectedSection, setSelectedSection] = useState(''); // New state for selected section
  const [loading, setLoading] = useState(true); // Loading state to handle data fetching
  const api = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  // Fetch accessToken from local storage on initial load
  useEffect(() => {
    const token = localStorage.getItem('accessToken'); // Get token from local storage
    if (token) {
      console.log("Access token from localStorage:", token); // Log the token
      setAccessToken(token);
    } else {
      console.error("No access token found in localStorage");
    }
  }, []); // Runs once when the component mounts

  useEffect(() => {
    if (accessToken) {
      fetchClientData();
    }
  }, [accessToken]); // Ensure data is refetched when accessToken changes

  const fetchClientData = async () => {
    console.log("Fetching client data for clientId:", clientId); // Logging clientId
    console.log("API URL:", api + `/clients/get-by-id/${clientId}`); // Logging API URL
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await axios.get(api + `/clients/get-by-id/${clientId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in request headers
        },
      });
      console.log("Client data fetched successfully:", response.data); // Log the response
      setClientData({
        ...response.data,
        logs: response.data.logs ? response.data.logs.split(',') : [],
      });
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching client data:', error);
      setLoading(false); // Set loading to false if there's an error
    }
  };

  const updateClientDetails = async (updatedData) => {
    console.log("Updating client details with:", updatedData); // Log the updated data
    try {
      const allData = {
        ...clientData,
        ...updatedData,
        logs: updatedData.logs ? updatedData.logs.join(',') : clientData.logs.join(','),
      };
      await axios.put(api + `/clients/update-by-id/${clientId}`, allData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`, // Include access token in request headers
        },
      });
      console.log('Client data updated successfully');
    } catch (error) {
      console.error('Error updating client data:', error);
    }
  };

  const addNote = () => {
    if (newNote.trim() !== '') {
      console.log("Adding new note:", newNote); // Log the new note
      const updatedLogs = [...clientData.logs, newNote.trim()];
      setClientData({ ...clientData, logs: updatedLogs });
      setNewNote('');
      updateClientDetails({ logs: updatedLogs });
    }
  };

  const deleteNote = (index) => {
    console.log("Deleting note at index:", index); // Log the index of the note being deleted
    const updatedLogs = clientData.logs.filter((_, i) => i !== index);
    setClientData({ ...clientData, logs: updatedLogs });
    updateClientDetails({ logs: updatedLogs });
  };

  // Display loading screen while data is being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!clientData) {
    return <div>No client data available</div>; // If data isn't available, show this message
  }

  const renderSection = () => {
    console.log("Rendering section:", selectedSection); // Log the selected section
    switch (selectedSection) {
      case 'email':
        return (
          <EmailSection
            clientData={clientData}
            conversations={conversations}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            accessToken={accessToken}
          />
        );
      case 'sidebar':
        return (
          <Sidebar
            clientData={clientData}
            setClientData={setClientData}
            updateClientDetails={updateClientDetails}
          />
        );
      case 'notes':
        return (
          <NotesSection
            clientData={clientData}
            newNote={newNote}
            setNewNote={setNewNote}
            addNote={addNote}
            deleteNote={deleteNote}
          />
        );
      case 'attachments':
        return (
          <AttachmentSection conversations={conversations} accessToken={accessToken} />
        );
      case 'sendEmail':
        return (
          <SendEmailForm clientEmail={clientData.emails} />
        );
      case 'pdfUpload':
        return (
          <PdfUploadComponent userEmail={clientData.emails} />
        );
      case 'installations':
        return (
          <Installations email={clientData.emails} />
        );
      case 'tickets':
        return (
          <Tickets email={clientData.emails} />
        );
      default:
        return <div>Select a section to view</div>;
    }
  };

  return (
    <div className="container">
      <Button onClick={() => navigate('/')} style={{ marginBottom: 10 }}>Back to the Clients Table</Button>

      <Space direction="horizontal" style={{ marginBottom: '20px' }}>
        {/* Commenting out the buttons for sections you want to omit */}
        {/* <Button onClick={() => setSelectedSection('email')}>Emails</Button> */}
        <Button onClick={() => setSelectedSection('sidebar')}>Mounting Date and Closure Percent</Button>
        <Button onClick={() => setSelectedSection('notes')}>Notes</Button>
        {/* <Button onClick={() => setSelectedSection('attachments')}>Attachments</Button> */}
        {/* <Button onClick={() => setSelectedSection('sendEmail')}>Send Email</Button> */}
        <Button onClick={() => setSelectedSection('pdfUpload')}>Upload Contract</Button>
        <Button onClick={() => setSelectedSection('tickets')}>Contract Tickets</Button>
      </Space>
      {renderSection()}
    </div>
  );
};

export default ClientDetailsComponent;
