import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Descriptions } from 'antd';

const LeadDetail = () => {
  const { id } = useParams();
  const [lead, setLead] = useState(null);
  const api = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(api + `leads/${id}`)
      .then(response => response.json())
      .then(data => setLead(data))
      .catch(error => console.error('Error fetching lead detail:', error));
  }, [id]);

  if (!lead) {
    return <div>Loading...</div>;
  }

  // Descriptions component from Ant Design to display lead details
  return (
    <Descriptions title="Lead Details" bordered>
      <Descriptions.Item label="First Name">{lead.Fornavn}</Descriptions.Item>
      <Descriptions.Item label="Last Name">{lead.Efternavn}</Descriptions.Item>
      <Descriptions.Item label="Email">{lead['E-mail']}</Descriptions.Item>
      <Descriptions.Item label="Phone">{lead.Telefon}</Descriptions.Item>
      <Descriptions.Item label="Delivery Address">{lead['Leveringsadresse ']}</Descriptions.Item>
      <Descriptions.Item label="Desired Delivery Time">{lead['Ønsket leveringstidspunkt ']}</Descriptions.Item>
      <Descriptions.Item label="Postal Code">{lead.Postnummer}</Descriptions.Item>
      <Descriptions.Item label="Cabinets">{lead.Kabinetter}</Descriptions.Item>
      <Descriptions.Item label="Surface Treatment">{lead.Overfladebehandling}</Descriptions.Item>
      <Descriptions.Item label="Drawers">{lead['Skuffer ']}</Descriptions.Item>
      <Descriptions.Item label="Quartzite">{lead.Quartzite}</Descriptions.Item>
      <Descriptions.Item label="Marble">{lead['Marmor ']}</Descriptions.Item>
      <Descriptions.Item label="Steel">{lead['Stål']}</Descriptions.Item>
      <Descriptions.Item label="Silestone">{lead['Silestone ']}</Descriptions.Item>
      <Descriptions.Item label="Wood">{lead['Træ']}</Descriptions.Item>
      <Descriptions.Item label="Corian">{lead['Corian ']}</Descriptions.Item>
      <Descriptions.Item label="Dekton">{lead.Dekton}</Descriptions.Item>
      <Descriptions.Item label="Wash">{lead.Wash}</Descriptions.Item>
      <Descriptions.Item label="Note">{lead.Note}</Descriptions.Item>
      {/* Render photo URLs if present */}
      {lead['Photo_1'] && <Descriptions.Item label="Photo 1"><a href={lead['Photo_1']} target="_blank" rel="noopener noreferrer">View Photo 1</a></Descriptions.Item>}
      {lead['Photo_2'] && <Descriptions.Item label="Photo 2"><a href={lead['Photo_2']} target="_blank" rel="noopener noreferrer">View Photo 2</a></Descriptions.Item>}
      {lead['Photo_3'] && <Descriptions.Item label="Photo 1"><a href={lead['Photo_3']} target="_blank" rel="noopener noreferrer">View Photo 3</a></Descriptions.Item>}
      {lead['Photo_4'] && <Descriptions.Item label="Photo 2"><a href={lead['Photo_4']} target="_blank" rel="noopener noreferrer">View Photo 4</a></Descriptions.Item>}
      {lead['Photo_5'] && <Descriptions.Item label="Photo 1"><a href={lead['Photo_5']} target="_blank" rel="noopener noreferrer">View Photo 5</a></Descriptions.Item>}
      {lead['Photo_6'] && <Descriptions.Item label="Photo 2"><a href={lead['Photo_6']} target="_blank" rel="noopener noreferrer">View Photo 6</a></Descriptions.Item>}
      {/* Add more photo fields as needed */}
      
    </Descriptions>
  );
};

export default LeadDetail;
