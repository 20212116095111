import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle, faInfoCircle, faBell, faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 85vh;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #555;

  &:hover {
    color: #000;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #0077ff;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #555;
  font-weight: 500;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #0077ff;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 20px;
  background-color: #0077ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0055cc;
  }
`;

const UrgencySelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const UrgencyOption = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const UrgencyInput = styled.input`
  display: none;
`;

const UrgencyIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 5px;
  transition: all 0.3s;
  color: white;

  ${({ color }) => `
    background-color: ${color};
    &:hover {
      background-color: ${color}CC;
    }
  `}

  ${UrgencyInput}:checked + & {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`;

const UrgencyLabel = styled.span`
  font-size: 12px;
  color: #555;
`;

const CreateTicketModal = ({ isModalOpen, setIsModalOpen, onTicketCreated }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [urgency, setUrgency] = useState('medium');
  const [routedFor, setRoutedFor] = useState([]);
  const [files, setFiles] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  const routingOptions = ['Annika', 'Frederik', 'Nicoline', 'Ivor', 'Helena'];

  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    setUserEmail(email || '');
  }, []);

  const handleRoutedForChange = (e) => {
    const value = e.target.value;
    setRoutedFor((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const uploadFilesAndGetLinks = async () => {
    const api = process.env.REACT_APP_BACKEND_URL;
    const fileLinks = [];

    for (let i = 0; i < files.length; i++) {
      const fileData = new FormData();
      fileData.append('file', files[i]);

      try {
        const uploadResponse = await axios.post(`${api}upload-file`, fileData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        fileLinks.push(uploadResponse.data.url);
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Error uploading file.');
        throw error; // Stop further execution if file upload fails
      }
    }

    return fileLinks;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = process.env.REACT_APP_BACKEND_URL;

    try {
      // Upload files first and get links
      const fileLinks = await uploadFilesAndGetLinks();

      const formData = {
        title,
        text:description,
        urgency,
        routedFor: routedFor.join(', '),
        userEmail,
        files:fileLinks,
      };

      // Create ticket with the file links
      await axios.post(`${api}create-ticket`, formData, {
        headers: {
        },
      });

      setTitle('');
      setDescription('');
      setUrgency('medium');
      setRoutedFor([]);
      setFiles([]);

      setIsModalOpen(false);
      onTicketCreated();
      toast.success('Ticket created successfully!');
    } catch (error) {
      console.error('Error creating the ticket:', error);
      toast.error('Error creating the ticket.');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen) return null;

  return (
    <Modal onClick={handleCloseModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={handleCloseModal}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Title>Create a New Support Ticket</Title>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Label htmlFor="emailInput">Email</Label>
            <Input
              id="emailInput"
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="titleInput">Title</Label>
            <Input
              id="titleInput"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter ticket title"
              required
            />
          </InputGroup>
          <InputGroup>
            <Label>Urgency</Label>
            <UrgencySelector>
              <UrgencyOption>
                <UrgencyInput
                  type="radio"
                  name="urgency"
                  value="critical"
                  checked={urgency === 'critical'}
                  onChange={(e) => setUrgency(e.target.value)}
                />
                <UrgencyIcon color="#FF4136">
                  <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                </UrgencyIcon>
                <UrgencyLabel>Critical</UrgencyLabel>
              </UrgencyOption>
              <UrgencyOption>
                <UrgencyInput
                  type="radio"
                  name="urgency"
                  value="major"
                  checked={urgency === 'major'}
                  onChange={(e) => setUrgency(e.target.value)}
                />
                <UrgencyIcon color="#FF851B">
                  <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                </UrgencyIcon>
                <UrgencyLabel>Major</UrgencyLabel>
              </UrgencyOption>
              <UrgencyOption>
                <UrgencyInput
                  type="radio"
                  name="urgency"
                  value="medium"
                  checked={urgency === 'medium'}
                  onChange={(e) => setUrgency(e.target.value)}
                />
                <UrgencyIcon color="#0074D9">
                  <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                </UrgencyIcon>
                <UrgencyLabel>Medium</UrgencyLabel>
              </UrgencyOption>
              <UrgencyOption>
                <UrgencyInput
                  type="radio"
                  name="urgency"
                  value="minor"
                  checked={urgency === 'minor'}
                  onChange={(e) => setUrgency(e.target.value)}
                />
                <UrgencyIcon color="#2ECC40">
                  <FontAwesomeIcon icon={faBell} size="lg" />
                </UrgencyIcon>
                <UrgencyLabel>Minor</UrgencyLabel>
              </UrgencyOption>
            </UrgencySelector>
          </InputGroup>
          <InputGroup>
            <Label>Routed For</Label>
            <CheckboxGroup>
              {routingOptions.map((option) => (
                <CheckboxLabel key={option}>
                  <Checkbox
                    type="checkbox"
                    value={option}
                    checked={routedFor.includes(option)}
                    onChange={handleRoutedForChange}
                  />
                  {option}
                </CheckboxLabel>
              ))}
            </CheckboxGroup>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="descriptionInput">Description</Label>
            <TextArea
              id="descriptionInput"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter ticket description"
              required
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="fileInput">Attach Files</Label>
            <FileInput
              type="file"
              id="fileInput"
              multiple
              onChange={handleFileChange}
            />
            <FileInputLabel htmlFor="fileInput">
              {files.length > 0 ? `${files.length} file(s) selected` : 'Choose files'}
            </FileInputLabel>
          </InputGroup>
          <SubmitButton type="submit">Create Ticket</SubmitButton>
        </Form>
        <ToastContainer position="bottom-right" />
      </ModalContent>
    </Modal>
  );
};

export default CreateTicketModal;
