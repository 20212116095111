import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFile } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Title = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 30px;
`;

const TicketList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TicketItem = styled.li`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TicketField = styled.p`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  font-weight: bold;
  color: #555;
`;

const Value = styled.span`
  color: #333;
`;

const ImageLink = styled.a`
  color: #007bff;
  text-decoration: none;
  margin-right: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Input = styled.input`
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  text-align: center;
  margin-bottom: 20px;
`;

const ChatStatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RedQuestionMark = styled.span`
  color: red;
  font-size: 24px;
  margin-left: 10px;
`;

const GreenSmiley = styled.span`
  color: green;
  font-size: 24px;
  margin-left: 10px;
`;

const ChatButton = styled(Link)`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');
  const [editingDate, setEditingDate] = useState(null);
  const [newDate, setNewDate] = useState('');
  const api = process.env.REACT_APP_BACKEND_URL;
  
const fetchTickets = async () => {
  const userEmail = localStorage.getItem('userEmail');
  if (!userEmail) {
    setError('User email not found. Please log in.');
    return;
  }

  try {
    const response = await axios.get(`${api}my-tickets`, { params: { email: userEmail } });
    setTickets(response.data);
  } catch (error) {
    setError('Error fetching tickets');
    console.error('Error fetching tickets:', error);
  }
};

  useEffect(() => {
    fetchTickets();
  }, [api]);

  const handleChangeDateClick = (ticketId, currentDate) => {
    setEditingDate(ticketId);
    setNewDate(currentDate || '');
  };

  const handleDateChange = async (ticketId) => {
    try {
      await axios.put(`${api}tickets/${ticketId}/mountingDate`, { date: newDate });
      setEditingDate(null);
      fetchTickets();
    } catch (error) {
      setError('Error updating ticket date');
    }
  };

  return (
    <Container>
      <Title>My Tickets</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <TicketList>
        {tickets.map(ticket => (
          <TicketItem key={ticket._id}>
            <TicketField>
              <Label>Ticket ID:</Label>
              <Value>{ticket._id}</Value>
            </TicketField>
            <TicketField>
              <Label>Email:</Label>
              <Value>{ticket.email}</Value>
            </TicketField>
            <TicketField>
              <Label>Text:</Label>
              <Value>{ticket.text}</Value>
            </TicketField>
             <TicketField>
              <Label>Files:</Label>
              <Value>
                {ticket.files && ticket.files.length > 0 ? (
                  ticket.files.map((file, index) => (
                    <ImageLink href={file} key={index} target="_blank" rel="noopener noreferrer">
                      <FaFile className="mr-2" />
                      File {index + 1}
                    </ImageLink>
                  ))
                ) : (
                  'No files attached'
                )}
              </Value>
            </TicketField>
            <TicketField>
              <Label>Status:</Label>
              <Value>{ticket.status}</Value>
            </TicketField>
            <TicketField>
              <Label>Chat Status:</Label>
              <ChatStatusWrapper>
                {ticket.chatStatus === 'Read by Heimwood' || ticket.chatStatus === 'No new messages' ? (
                  <>
                    <Value>No new messages</Value>
                    <GreenSmiley>😊</GreenSmiley>                 
                  </>
                ) : (
                  <>
                    <Value>{ticket.chatStatus}</Value>
                    {ticket.chatStatus === 'New Message from User' && (
                      <RedQuestionMark>!</RedQuestionMark>
                    )}
                  </>
                )}
              </ChatStatusWrapper>
            </TicketField>
            <TicketField>
              <Label>Note:</Label>
              <Value>{ticket.note}</Value>
            </TicketField>
            <TicketField>
              <Label>Routed For:</Label>
              <Value>{ticket.routedFor}</Value>
            </TicketField>
            <TicketField>
              <Label>Created At:</Label>
              <Value>{new Date(ticket.createdAt).toLocaleString()}</Value>
            </TicketField>
            <TicketField>
              <Label>Type:</Label>
              <Value>{ticket.type}</Value>
            </TicketField>
            <TicketField>
              <Label>Date:</Label>
              {editingDate === ticket._id ? (
                <>
                  <Input
                    type="date"
                    value={newDate}
                    onChange={(e) => setNewDate(e.target.value)}
                  />
                  <Button onClick={() => handleDateChange(ticket._id)}>Save</Button>
                  <Button onClick={() => setEditingDate(null)}>Cancel</Button>
                </>
              ) : (
                <>
                  <Value>{ticket.date}</Value>
                  <Button onClick={() => handleChangeDateClick(ticket._id, ticket.date)}>
                    Change Date
                  </Button>
                </>
              )}
            </TicketField>
            <TicketField>
              <ChatButton to={`/chat/${ticket._id}`}>
                Open Chat
              </ChatButton>
            </TicketField>
          </TicketItem>
        ))}
      </TicketList>
    </Container>
  );
};

export default MyTickets;