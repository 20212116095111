import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, message } from 'antd';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const smallButtonStyle = {
  fontSize: '12px',
  padding: '5px 10px',
  marginLeft: '-5px',
};

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const api = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken'); // Get accessToken from localStorage
    if (accessToken) {
      fetchClients(accessToken); // Fetch clients with accessToken
    } else {
      message.error('No access token found. Please login.');
      navigate('/login'); // Redirect to login if no token is found
    }
  }, [navigate]);

  const fetchClients = async (accessToken) => {
    console.log("Fetching clients from this URL: " + api + '/clients/get-all');
    try {
      const response = await axios.get(api + '/clients/get-all', {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Add token to Authorization header
        },
      });
      const sortedData = response.data.sort(
        (a, b) => getTimeDifferenceInHours(b.createdAt) - getTimeDifferenceInHours(a.createdAt)
      );
      setData(sortedData);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      message.error('Failed to fetch clients');
    }
  };

  const handleSaveClients = async () => {
    const accessToken = localStorage.getItem('accessToken'); // Get accessToken from localStorage
    if (!accessToken) {
      message.error('No access token found. Please login.');
      return;
    }
    try {
      for (const item of data) {
        const clientData = {
          name: item.name,
          emails: item.emails,
          client_number: item.client_number,
          status: item.status,
          note: item.note,
          closure_percent: item.closure_percent,
          type: 'client',
        };
        if (item._id && !item._id.startsWith('temp-')) {
          await axios.put(api + `/clients/update-by-id/${item._id}`, clientData, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`, // Add token to Authorization header
            },
          });
        } else {
          await axios.post(api + '/clients/create', clientData, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`, // Add token to Authorization header
            },
          });
        }
      }
      message.success('Clients saved successfully');
      fetchClients(accessToken);
    } catch (error) {
      console.error('Error saving clients:', error);
      message.error('Failed to save clients');
    }
  };

  const handleDeleteRow = async (_id) => {
    const accessToken = localStorage.getItem('accessToken'); // Get accessToken from localStorage
    if (!accessToken) {
      message.error('No access token found. Please login.');
      return;
    }

    try {
      await axios.delete(api + `/clients/delete-by-id/${_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Add token to Authorization header
        },
      });
      setData(data.filter((item) => item._id !== _id));
      message.success('Client deleted successfully');
    } catch (error) {
      console.error('Error deleting client:', error);
      message.error('Failed to delete client');
    }
  };

  const handleAddRow = () => {
    const newRow = {
      _id: `temp-${Date.now()}`, // Temporary ID for new row
      name: '',
      emails: '',
      client_number: '',
      status: '',
      note: '',
      closure_percent: '0', // Default closure percent
      createdAt: new Date().toISOString(),
    };
    setData([newRow, ...data]);
  };


  const columns = [
    {
      title: 'CLIENT NUMBER',
      dataIndex: 'client_number',
      key: 'client_number',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleCellChange(record._id, 'client_number', e.target.value)}
        />
      ),
    },
    {
      title: 'NAMES',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleCellChange(record._id, 'name', e.target.value)}
        />
      ),
    },
    {
      title: 'EMAILS',
      dataIndex: 'emails',
      key: 'emails',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleCellChange(record._id, 'emails', e.target.value)}
        />
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleCellChange(record._id, 'status', e.target.value)}
        />
      ),
    },
    {
      title: 'CLOSURE PERCENT',
      dataIndex: 'closure_percent',
      key: 'closure_percent',
      render: (text, record) => {
        let backgroundColor = '#fff'; // default background color
        if (text === '100') {
          backgroundColor = 'green'; // green for 100
        } else if (text !== '' && text !== '0' && parseInt(text, 10) < 100) {
          backgroundColor = 'orange'; // orange for <100 and not 0 or empty
        }
        return (
          <Input
            type="number"
            value={text}
            style={{ backgroundColor }}
            onChange={(e) => handleCellChange(record._id, 'closure_percent', e.target.value)}
          />
        );
      },
    },
    {
      title: 'NOTE',
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleCellChange(record._id, 'note', e.target.value)}
        />
      ),
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'time',
      render: (createdAt) => getTimeDifferenceInHours(createdAt) + ' hours ago',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleDeleteRow(record._id)}>Delete</Button>
          <Button onClick={() => handleMoreClick(record._id)}>More</Button>
        </Space>
      ),
    },
  ];

  const handleCellChange = (_id, dataIndex, value) => {
    const newData = [...data];
    const index = newData.findIndex((item) => item._id === _id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [dataIndex]: value });
      setData(newData);
    }
  };

  const handleMoreClick = (_id) => {
    navigate(`/clients/${_id}`);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const getTimeDifferenceInHours = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    return Math.floor((now.getTime() - createdAtDate.getTime()) / 3600000); // 3600000 ms in an hour
  };

  const filteredData = data
    .filter((item) => {
      const searchTextLower = searchText.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchTextLower) ||
        item.emails?.toLowerCase().includes(searchTextLower) ||
        item.client_number?.toLowerCase().includes(searchTextLower) ||
        item.status?.toLowerCase().includes(searchTextLower) ||
        item.closure_percent?.toString().toLowerCase().includes(searchTextLower) ||
        item.note?.toLowerCase().includes(searchTextLower)
      );
    })
    .sort((a, b) => getTimeDifferenceInHours(a.createdAt) - getTimeDifferenceInHours(b.createdAt));

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
<Button onClick={handleAddRow} style={smallButtonStyle}>
          Add Row
        </Button>
        <Input
          placeholder="Search"
          value={searchText}
          onChange={handleSearch}
          style={{ width: '200px' }}
        />
        <Button onClick={handleSaveClients} style={smallButtonStyle}>
          Save Clients
        </Button>
        <Button onClick={() => navigate('/home')} style={smallButtonStyle}>
          Home
        </Button>
      </Space>
      <Table columns={columns} dataSource={filteredData} rowKey="_id" />
    </div>
  );
};

export default TableComponent;
