import React from 'react';

const Sidebar = ({ clientData, setClientData, updateClientDetails }) => {
  return (
    <div className="sidebar">
      <div className="mounting-date">
        <label htmlFor="mountingDate">Mounting Date:</label>
        <input
          type="text" // Change this to text to allow for arbitrary string formats
          id="mountingDate"
          value={clientData.mounting_date || ''} // Display as is
          onChange={(e) => {
            const updatedValue = e.target.value;
            setClientData({ ...clientData, mounting_date: updatedValue });
            updateClientDetails({ mounting_date: updatedValue });
          }}
        />
      </div>
      <div className="closure-percent">
        <label htmlFor="closurePercent">Closure Percent:</label>
        <input
          type="number"
          id="closurePercent"
          value={clientData.closure_percent || ''} // Ensure value is not undefined for a controlled component
          onChange={(e) => {
            const updatedValue = e.target.value;
            setClientData({ ...clientData, closure_percent: updatedValue });
            updateClientDetails({ closure_percent: updatedValue });
          }}
        />
      </div>
    </div>
  );
};

export default Sidebar;
