import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleButton, GoogleIcon, LoginContainer, LoginCard, FormContainer, Title, LoadingOverlay, LoadingSpinner, GlobalStyle } from './styles/LoginStyles'; // Importing styles

const Login = () => {
  const api = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => handleCredentialResponse(response),
    onError: () => {
      console.error('Google login failed.');
      toast.error('Google login failed. Please try again.');
    },
    scope: 'openid profile email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send',
    responseType: 'id_token token'
  });

  const handleCredentialResponse = (response) => {
  setIsLoading(true);
  console.log('Google login response:', response);

  fetch(`${api}/auth/google-login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ accessTokenGoogle: response.access_token })
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Login failed');
      }
      return res.json();
    })
    .then((data) => {
      console.log('Server response:', data);
      if (data.email && data.token) {  // Check if email and token are returned
        // Save the email and token in localStorage
        localStorage.setItem('userEmail', data.email);
        localStorage.setItem('accessToken', data.token);  // Save the JWT token
        
        toast.success('Login successful');
        navigate('/');  // Redirect to the home or dashboard page
      } else {
        throw new Error('Email or token not received from server');
      }
    })
    .catch((error) => {
      console.error('Google login error:', error);
      toast.error(error.message || 'Google login error');
    })
    .finally(() => {
      setIsLoading(false);
    });
};


  return (
    <>
      <GlobalStyle />
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
      <LoginContainer>
        <LoginCard>
          <FormContainer>
            <Title>Login</Title>
            <GoogleButton onClick={googleLogin}>
              <GoogleIcon />
              Sign in with Google
            </GoogleButton>
          </FormContainer>
        </LoginCard>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      </LoginContainer>
    </>
  );
};

export default Login;
