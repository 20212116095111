import React, { useState, useEffect } from 'react';

const AttachmentSection = ({ conversations, accessToken }) => {
  const [imageAttachments, setImageAttachments] = useState([]);
  const [pdfAttachments, setPdfAttachments] = useState([]);

  useEffect(() => {
    const fetchAttachments = async () => {
  const attachmentPromises = conversations.reduce((acc, conversation) => {
    // Check if parts is defined and is an array before proceeding to filter
    if (conversation.payload.parts && Array.isArray(conversation.payload.parts)) {
      const attachmentParts = conversation.payload.parts.filter(
        (part) =>
          (part.mimeType.startsWith('image/') || part.mimeType === 'application/pdf') &&
          part.mimeType !== 'text/plain' &&
          part.mimeType !== 'multipart/alternative'
      );

      attachmentParts.forEach((part) => {
        const attachmentId = part.body.attachmentId;
        const attachmentUrl = `https://www.googleapis.com/gmail/v1/users/me/messages/${conversation.id}/attachments/${attachmentId}?access_token=${accessToken}`;

        acc.push(
          fetch(attachmentUrl)
            .then((response) => response.json())
            .then((data) => {
              let base64String = data.data.replace(/-/g, '+').replace(/_/g, '/');
              const padLength = base64String.length % 4;
              if (padLength) {
                base64String += '='.repeat(4 - padLength);
              }

              const byteCharacters = atob(base64String);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: part.mimeType });
              const blobUrl = URL.createObjectURL(blob);
              return {
                mimeType: part.mimeType,
                fileName: part.mimeType.startsWith('image/') ? `Image-${conversation.id}.png` : `Document-${conversation.id}.pdf`,
                url: blobUrl,
              };
            })
        );
      });
    }
    return acc;
  }, []);

  const resolvedAttachments = await Promise.all(attachmentPromises);
  setImageAttachments(resolvedAttachments.filter(att => att.mimeType.startsWith('image/')));
  setPdfAttachments(resolvedAttachments.filter(att => att.mimeType === 'application/pdf'));
};


    fetchAttachments();
  }, [conversations, accessToken]);

  return (
    <div>
      <div className="attachment-section">
        <h2>Image Attachments</h2>
        {imageAttachments.length === 0 ? (
          <p>No image attachments found.</p>
        ) : (
          <ul className="download-links">
            {imageAttachments.map((attachment, index) => (
              <li key={index}>
                <a href={attachment.url} download={attachment.fileName}>
                  Download {attachment.fileName}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="attachment-section">
        <h2>PDF Attachments</h2>
        {pdfAttachments.length === 0 ? (
          <p>No PDF attachments found.</p>
        ) : (
          <ul className="download-links">
            {pdfAttachments.map((attachment, index) => (
              <li key={index}>
                <a href={attachment.url} download={attachment.fileName}>
                  Download {attachment.fileName}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AttachmentSection;
