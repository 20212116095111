import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFile } from 'react-icons/fa';
import CreateTicketModal from './CreateTicketModal';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Title = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 30px;
`;

const TicketList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TicketItem = styled.li`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TicketField = styled.p`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  font-weight: bold;
  color: #555;
`;

const Value = styled.span`
  color: #333;
`;

const ImageLink = styled.a`
  color: #007bff;
  text-decoration: none;
  margin-right: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Input = styled.input`
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

const Select = styled.select`
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  text-align: center;
  margin-bottom: 20px;
`;

const ChatStatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RedQuestionMark = styled.span`
  color: red;
  font-size: 24px;
  margin-left: 10px;
`;

const GreenSmiley = styled.span`
  color: green;
  font-size: 24px;
  margin-left: 10px;
`;

const ChatButton = styled(Link)`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const RoutedForTag = styled.span`
  background-color: #e9ecef;
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 5px;
  font-size: 0.9em;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FilterSelect = styled(Select)`
  flex: 1;
  margin-right: 10px;
`;

const CreateTicketButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const AllTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [error, setError] = useState('');
  const [editingDate, setEditingDate] = useState(null);
  const [newDate, setNewDate] = useState('');
  const [editingRoutedFor, setEditingRoutedFor] = useState(null);
  const [newRoutedFor, setNewRoutedFor] = useState([]);
  const [editingStatus, setEditingStatus] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [routedForFilter, setRoutedForFilter] = useState('All');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [newNote, setNewNote] = useState('');
  const api = process.env.REACT_APP_BACKEND_URL;

  const routingOptions = ['Annika', 'Frederik', 'Nicoline', 'Oscar', 'Ivor','Helena'];
  const statusOptions = ['Created', 'Working on it', 'Closed'];

  const fetchTickets = async () => {
    try {
      const response = await axios.get(api + 'allTickets');
      setTickets(response.data);
      setFilteredTickets(response.data);
      console.log(response.data);
    } catch (error) {
      setError('Error fetching tickets');
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  useEffect(() => {
    let result = tickets;
    if (statusFilter !== 'All') {
      result = result.filter(ticket => ticket.status === statusFilter);
    }
    if (routedForFilter !== 'All') {
      result = result.filter(ticket => ticket.routedFor.includes(routedForFilter));
    }
    setFilteredTickets(result);
  }, [statusFilter, routedForFilter, tickets]);

  const handleChangeDateClick = (ticketId, currentDate) => {
    setEditingDate(ticketId);
    setNewDate(currentDate || '');
  };

  const handleDateChange = async (ticketId, email) => {
    try {
      await axios.put(`${api}tickets/${ticketId}/mountingDate`, { date: newDate });
      setEditingDate(null);
      fetchTickets();

      await axios.post(`${api}notify-client`, {
        receivingEmail: email,
        subject: 'Ticket Date Changed',
        text: `<p>The date of your ticket with ID ${ticketId} has been changed to ${newDate}.</p>`
      });

    } catch (error) {
      setError('Error updating ticket date');
    }
  };

  const handleChangeRoutedForClick = (ticketId, currentRoutedFor) => {
    setEditingRoutedFor(ticketId);
    setNewRoutedFor(currentRoutedFor ? currentRoutedFor.split(',').map(item => item.trim()) : []);
  };

  const handleRoutedForChange = async (ticketId) => {
    try {
      await axios.put(`${api}tickets/${ticketId}/routedFor`, { routedFor: newRoutedFor.join(', ') });
      setEditingRoutedFor(null);
      fetchTickets();
    } catch (error) {
      setError('Error updating routed for');
    }
  };

  const handleRoutedForSelect = (option) => {
    setNewRoutedFor(prev => {
      const filteredPrev = prev.filter(item => item !== 'none');
      return filteredPrev.includes(option)
        ? filteredPrev.filter(item => item !== option)
        : [...filteredPrev, option];
    });
  };

  const handleChangeStatusClick = (ticketId, currentStatus) => {
    setEditingStatus(ticketId);
    setNewStatus(currentStatus || '');
  };

  const handleStatusChange = async (ticketId) => {
    try {
      await axios.put(`${api}tickets/${ticketId}/status`, { status: newStatus });
      setEditingStatus(null);
      fetchTickets();
    } catch (error) {
      setError('Error updating ticket status');
    }
  };

  const handleChangeNoteClick = (ticketId, currentNote) => {
    setEditingNote(ticketId);
    setNewNote(currentNote || '');
  };

  const handleNoteChange = async (ticketId) => {
    try {
      await axios.put(`${api}tickets/${ticketId}/note`, { note: newNote });
      setEditingNote(null);
      fetchTickets();
    } catch (error) {
      setError('Error updating ticket note');
    }
  };

  return (
    <Container>
      <CreateTicketButton onClick={() => setIsModalOpen(true)}>Create Ticket</CreateTicketButton>
      <Title>Tickets</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FilterContainer>
        <FilterSelect
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="All">All Statuses</option>
          {statusOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </FilterSelect>
        <FilterSelect
          value={routedForFilter}
          onChange={(e) => setRoutedForFilter(e.target.value)}
        >
          <option value="All">All Routed For</option>
          {routingOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </FilterSelect>
      </FilterContainer>
      <TicketList>
        {filteredTickets.map(ticket => (
          <TicketItem key={ticket._id}>
            <TicketField>
              <Label>Ticket ID:</Label>
              <Value>{ticket._id}</Value>
            </TicketField>
            <TicketField>
              <Label>Email:</Label>
              <Value>{ticket.email}</Value>
            </TicketField>
            <TicketField>
              <Label>Text:</Label>
              <Value>{ticket.text}</Value>
            </TicketField>
             <TicketField>
              <Label>Files:</Label>
              <Value>
                {ticket.files && ticket.files.length > 0 ? (
                  ticket.files.map((file, index) => (
                    <ImageLink
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFile className="mr-2" />
                      File {index + 1}
                    </ImageLink>
                  ))
                ) : (
                  'No files attached'
                )}
              </Value>
            </TicketField>
            <TicketField>
              <Label>Status:</Label>
              {editingStatus === ticket._id ? (
                <>
                  <Select
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                  >
                    {statusOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </Select>
                  <Button onClick={() => handleStatusChange(ticket._id)}>Save</Button>
                  <Button onClick={() => setEditingStatus(null)}>Cancel</Button>
                </>
              ) : (
                <>
                  <Value>{ticket.status}</Value>
                  <Button onClick={() => handleChangeStatusClick(ticket._id, ticket.status)}>
                    Change Status
                  </Button>
                </>
              )}
            </TicketField>
            <TicketField>
              <Label>Chat Status:</Label>
              <ChatStatusWrapper>
                {ticket.chatStatus === 'Read by Heimwood' || ticket.chatStatus === 'No new messages' ? (
                  <>
                    <Value>No new messages</Value>
                    <GreenSmiley>😊</GreenSmiley>                 
                  </>
                ) : (
                  <>
                    <Value>{ticket.chatStatus}</Value>
                    {ticket.chatStatus === 'New Message from User' && (
                      <RedQuestionMark>!</RedQuestionMark>
                    )}
                  </>
                )}
              </ChatStatusWrapper>
            </TicketField>
            <TicketField>
              <Label>Note:</Label>
              {editingNote === ticket._id ? (
                <>
                  <TextArea
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                  />
                  <Button onClick={() => handleNoteChange(ticket._id)}>Save</Button>
                  <Button onClick={() => setEditingNote(null)}>Cancel</Button>
                </>
              ) : (
                <>
                  <Value>{ticket.note}</Value>
                  <Button onClick={() => handleChangeNoteClick(ticket._id, ticket.note)}>
                    Update Note
                  </Button>
                </>
              )}
            </TicketField>
            <TicketField>
              <Label>Routed For:</Label>
              {editingRoutedFor === ticket._id ? (
                <>
                  <div>
                    {routingOptions.map(option => (
                      <label key={option}>
                        <input
                          type="checkbox"
                          checked={newRoutedFor.includes(option)}
                          onChange={() => handleRoutedForSelect(option)}
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                  <Button onClick={() => handleRoutedForChange(ticket._id)}>Save</Button>
                  <Button onClick={() => setEditingRoutedFor(null)}>Cancel</Button>
                </>
              ) : (
                <>
                  <Value>
                    {ticket.routedFor.split(',').map(person => (
                      <RoutedForTag key={person.trim()}>{person.trim()}</RoutedForTag>
                    ))}
                  </Value>
                  <Button onClick={() => handleChangeRoutedForClick(ticket._id, ticket.routedFor)}>
                    Change Routed For
                  </Button>
                </>
              )}
            </TicketField>
            <TicketField>
              <Label>Date:</Label>
              {editingDate === ticket._id ? (
                <>
                  <Input
                    type="datetime-local"
                    value={newDate}
                    onChange={(e) => setNewDate(e.target.value)}
                  />
                  <Button onClick={() => handleDateChange(ticket._id, ticket.email)}>Save</Button>
                  <Button onClick={() => setEditingDate(null)}>Cancel</Button>
                </>
              ) : (
                <>
                  <Value>{ticket.date}</Value>
                  <Button onClick={() => handleChangeDateClick(ticket._id, ticket.date)}>
                    Change Date
                  </Button>
                </>
              )}
            </TicketField>
            <TicketField>
              <Label>Type:</Label>
              <Value>{ticket.type}</Value>
            </TicketField>
            <TicketField>
              <ChatButton to={`/chat/${ticket._id}`}>Go to Chat</ChatButton>
            </TicketField>
          </TicketItem>
        ))}
      </TicketList>

     <CreateTicketModal
        isModalOpen={isModalOpen} // Updated to match the expected prop name
        setIsModalOpen={setIsModalOpen} // Pass the setIsModalOpen function
        onTicketCreated={fetchTickets} // Function to fetch tickets after creating a new one
      />
    </Container>
  );
};

export default AllTickets;