import React, { useEffect, useState } from 'react';
import { Button, Table, Spin } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const BookingPictures = () => {
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPictures = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}booking-pictures`);
        const data = await response.json();
        // Sort data by createdAt in descending order to show the newest first
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const formattedData = sortedData.map(item => ({
          ...item,
          createdAtFormatted: moment(item.createdAt).fromNow(),  // Use fromNow for a more natural expression
        }));
        setPictures(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching booking pictures:', error);
      }
    };

    fetchPictures();
  }, []);

  const columns = [
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'email',
    },
    {
      title: 'Photo 1',
      dataIndex: 'Photo_1',
      key: 'photo1',
      render: text => text ? <a href={text} target="_blank" rel="noopener noreferrer">View Photo</a> : 'N/A',
    },
    {
      title: 'Photo 2',
      dataIndex: 'Photo_2',
      key: 'photo2',
      render: text => text ? <a href={text} target="_blank" rel="noopener noreferrer">View Photo</a> : 'N/A',
    },
    {
      title: 'Photo 3',
      dataIndex: 'Photo_3',
      key: 'photo3',
      render: text => text ? <a href={text} target="_blank" rel="noopener noreferrer">View Photo</a> : 'N/A',
    },
    {
      title: 'Photo 4',
      dataIndex: 'Photo_4',
      key: 'photo4',
      render: text => text ? <a href={text} target="_blank" rel="noopener noreferrer">View Photo</a> : 'N/A',
    },
    {
      title: 'Time Since Creation',
      dataIndex: 'createdAtFormatted',
      key: 'time',
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <div style={{ marginBottom: 16 }}>
            <Button onClick={() => navigate('/')} style={{ marginRight: 8 }}>Client Table</Button>
            <Button onClick={() => navigate('/fa-et-tilbud-leads')} style={{ marginRight: 8 }}>Fa et Tilbud Leads</Button>
            <Button onClick={() => navigate('/quick-leads')} style={{ marginRight: 8 }}>Quick Config Leads</Button>
            <Button onClick={() => navigate('/booking-leads')} style={{ marginRight: 8 }}>Booking Leads</Button>
            <Button onClick={() => navigate('/booking-pictures')}>Booking Pictures</Button>
          </div>
          <Table
            columns={columns}
            dataSource={pictures}
            rowKey="_id"
            pagination={{ pageSize: 10 }}
          />
        </>
      )}
    </div>
  );
};

export default BookingPictures;
