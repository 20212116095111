import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as XLSX from "xlsx";
import { gapi } from "gapi-script";

const CLIENT_ID = "48765774603-vihf4n03onm7qd1kpnrpmt36sa9aiv9n.apps.googleusercontent.com";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

const carpenters = [
  { value: "Frederik", label: "Frederik" },
  { value: "Oscar", label: "Oscar" },
  { value: "Jacob", label: "Jacob" },
  { value: "Aleksander", label: "Aleksander" },
];

const carpenters_emails = {
  Frederik: "ivor@heimwood.dk",
  Oscar: "ivor.baricevic3@gmail.com",
  Jacob: "thewordhuntgame@gmail.com",
  Aleksander: "kronholmbyg@hotmail1.com",
};

const carpenters_colors = {
  Frederik: "7",
  Oscar: "11",
  Jacob: "2",
  Aleksander: "5",
};

const CreateMounting = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedCarpenters, setSelectedCarpenters] = useState([]);
  const [timeSlots, setTimeSlots] = useState([
    "8:00 - 16:00",
    "08:00 - 12:00",
    "12:00 - 16:00",
  ]);
  const [newTimeSlot, setNewTimeSlot] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [gapiReady, setGapiReady] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => window.gapi.load("client:auth2", initClient);
    document.body.appendChild(script);
  }, []);

  const initClient = () => {
    gapi.client
      .init({
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        authInstance.isSignedIn.listen(updateSigninStatus);
        updateSigninStatus(authInstance.isSignedIn.get());
        setGapiReady(true);
      })
      .catch((error) => {
        console.error("Error loading GAPI client for API", error);
        setStatusMessage("Error loading GAPI client for API");
      });
  };

  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      setStatusMessage("Logged in successfully, ready to create events.");
    } else {
      setStatusMessage("Not logged in. Please log in to create events.");
    }
  };

  const handleAuthClick = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    if (!authInstance.isSignedIn.get()) {
      authInstance.signIn();
    } else {
      authInstance.signOut();
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      setClients(
        data.map((client) => ({
          value: client["__EMPTY_3"],
          label: `${client["__EMPTY"]} - ${client["Kontakter"]}`,
        }))
      );
    };
    reader.readAsBinaryString(file);
  };

  const handleClientSelectionChange = (selectedOptions) => {
    setSelectedClients(selectedOptions);
  };

  const handleTimeSlotChange = (selectedOption) => {
    setTimeSlot(selectedOption);
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, newTimeSlot]);
    setNewTimeSlot("");
  };

  const handleCarpenterChange = (selectedOptions) => {
    setSelectedCarpenters(selectedOptions);
  };

  const createEvent = async () => {
    if (!gapi.auth2.getAuthInstance().isSignedIn.get()) {
      setStatusMessage("Please log in to create events.");
      return;
    }

    const [startTime, endTime] = timeSlot.value.split(" - ");
    const startDate = new Date(selectedDate);
    startDate.setHours(
      parseInt(startTime.split(":")[0]),
      parseInt(startTime.split(":")[1]),
      0
    );
    const endDate = new Date(selectedDate);
    endDate.setHours(
      parseInt(endTime.split(":")[0]),
      parseInt(endTime.split(":")[1]),
      0
    );

    const attendees = selectedClients.map((client) => ({
      email: client.value,
    }));
    selectedCarpenters.forEach((carpenter) => {
      if (carpenters_emails[carpenter.value]) {
        attendees.push({ email: carpenters_emails[carpenter.value] });
      }
    });

    let colorId = "3"; // Default color
    if (selectedCarpenters.length === 1) {
      colorId = carpenters_colors[selectedCarpenters[0].value] || colorId;
    }

    const event = {
      summary: "New Appointment",
      location: "Virtual Meeting Room",
      description:
        "Kære kunde\n\nDenne invitation er blot til info om at vi kommer og monterer hos jer.\n\nAnkomsttid er ca.",
      start: {
        dateTime: startDate.toISOString(),
        timeZone: "Europe/Copenhagen",
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: "Europe/Copenhagen",
      },
      attendees: attendees.map((attendee) => ({ email: attendee.email })),
      sendNotifications: true, // Send invitation emails
      sendUpdates: "all",
      colorId: colorId,
    };

    console.log('Creating event with the following details:', event); // Log event details

    const request = gapi.client.calendar.events.insert({
      calendarId: "primary", // Specify the calendar ID here
      resource: event,
    });

    request.execute((event) => {
      if (event.error) {
        console.error('Error creating event:', event.error);
        setStatusMessage(`Error creating event: ${event.error.message}`);
      } else {
        setStatusMessage(`Event created: ${event.htmlLink}`);
        console.log("Event created:", event.htmlLink);
      }
    });
  };

  return (
    <div>
      <h1>Google Calendar Event Creator</h1>
      {!gapiReady ? (
        <p>Loading Google API...</p>
      ) : (
        <>
          <button onClick={handleAuthClick}>
            {gapi.auth2.getAuthInstance().isSignedIn.get()
              ? "Sign Out"
              : "Sign In"}
          </button>
          <DatePicker selected={selectedDate} onChange={setSelectedDate} />
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          {clients.length > 0 && (
            <Select
              isMulti
              options={clients}
              onChange={handleClientSelectionChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          )}
          <Select
            value={timeSlot}
            onChange={handleTimeSlotChange}
            options={timeSlots.map((time) => ({ value: time, label: time }))}
          />
          <input
            type="text"
            value={newTimeSlot}
            onChange={(e) => setNewTimeSlot(e.target.value)}
          />
          <button onClick={handleAddTimeSlot}>Add Time Slot</button>
          <Select
            isMulti
            value={selectedCarpenters}
            onChange={handleCarpenterChange}
            options={carpenters}
          />
          <button onClick={createEvent}>Create Calendar Event</button>
          <div>{statusMessage}</div>
        </>
      )}
    </div>
  );
};

export default CreateMounting;
