import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Input } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ContactLeads = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hours, setHours] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}contact-leads`);
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(b.dateOfCreation) - new Date(a.dateOfCreation));
        const formattedData = sortedData.map(lead => ({
          ...lead,
          'dateOfCreationFormatted': moment(lead.dateOfCreation).fromNow(),
        }));
        setLeads(formattedData);
        setFilteredLeads(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, []);

  const getTimeDifferenceInHours = (dateOfCreation) => {
    const createdAtDate = new Date(dateOfCreation);
    const now = new Date();
    return Math.abs(Math.floor((now - createdAtDate) / 3600000)); // 3600000 ms in an hour
  };

  const handleFilterLeads = () => {
    if (hours === '') {
      setFilteredLeads(leads); // If no hours specified, show all leads
    } else {
      const filtered = leads.filter(lead => getTimeDifferenceInHours(lead.dateOfCreation) <= hours);
      setFilteredLeads(filtered);
    }
  };

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Time Since Creation',
      dataIndex: 'dateOfCreationFormatted',
      key: 'time',
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <div style={{ marginBottom: 16 }}>
            <Button onClick={() => navigate('/home')} style={{ marginRight: 8 }}>Home</Button>
            {/* Add more navigation buttons if needed */}
          </div>
          <div style={{ marginBottom: 16 }}>
            <span>Total Leads: {filteredLeads.length}</span>
            <div style={{ marginTop: 16 }}>
              <Input 
                type="number" 
                placeholder="Enter hours" 
                value={hours} 
                onChange={handleHoursChange} 
                style={{ width: 200, marginRight: 8 }} 
              />
              <Button onClick={handleFilterLeads}>Filter Leads</Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredLeads}
            rowKey="_id"
            pagination={{ pageSize: 10 }}
          />
        </>
      )}
    </div>
  );
};

export default ContactLeads;
