import React from 'react';
import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#f0f2f5',
  flexDirection: 'column',
  padding: '20px',
};

const smallButtonStyle = {
  fontSize: '16px',
  padding: '12px 24px',
  margin: '8px 0',
  backgroundColor: '#1890ff',
  color: '#fff',
  borderRadius: '5px',
  border: 'none',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease',
  width: '100%',
  maxWidth: '300px',
  textAlign: 'center',
};

const buttonContainerStyle = {
  width: '100%',
  maxWidth: '320px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLogin = () => {
    localStorage.clear();
    handleNavigate('/');
  };

  return (
    <div style={containerStyle}>
      <Title level={2} style={{ marginBottom: '30px', color: '#333' }}>
        Welcome to the Home Page
      </Title>
      <div style={buttonContainerStyle}>
        <Button onClick={() => handleNavigate('/')} style={smallButtonStyle}>
          Clients Table
        </Button>
        <Button onClick={() => handleNavigate('/fa-et-tilbud-leads')} style={smallButtonStyle}>
          Fa et Tilbud Leads
        </Button>
        <Button onClick={() => handleNavigate('/quick-leads')} style={smallButtonStyle}>
          Quick Config Leads
        </Button>
        <Button onClick={() => handleNavigate('/booking-leads')} style={smallButtonStyle}>
          Booking Leads
        </Button>
        <Button onClick={() => handleNavigate('/booking-pictures')} style={smallButtonStyle}>
          Booking Pictures
        </Button>
        <Button onClick={() => handleNavigate('/contact-leads')} style={smallButtonStyle}>
          Contact Us Leads
        </Button>
        <Button onClick={() => handleNavigate('/new-emails')} style={smallButtonStyle}>
          New Emails
        </Button>
        <Button onClick={() => handleNavigate('/allTickets')} style={smallButtonStyle}>
          All Tickets
        </Button>
        <Button onClick={() => handleNavigate('/my-tickets')} style={smallButtonStyle}>
          My Tickets
        </Button>
        <Button onClick={() => handleNavigate('/Catalogue')} style={smallButtonStyle}>
          Catalogue
        </Button>
        <Button onClick={handleLogin} style={smallButtonStyle}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default HomePage;
