import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
`;

const ChatTitle = styled.h1`
  text-align: center;
  color: #075e54;
`;

const MessagesContainer = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
`;

const Message = styled.div`
  max-width: 60%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.sent === 'true' ? '#dcf8c6' : '#fff'};
  align-self: ${props => props.sent === 'true' ? 'flex-end' : 'flex-start'};
  text-align: ${props => props.sent === 'true' ? 'right' : 'left'};
`;

const InputContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: #fff;
`;

const MessageInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
`;

const SendButton = styled.button`
  background-color: #075e54;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #128c7e;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #075e54;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ChatPage = () => {
  const { ticketId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const api = process.env.REACT_APP_BACKEND_URL;

  const fetchMessages = async () => {
    setLoading(true);
    console.log(`Fetching messages for ticket ${ticketId}...`);
    try {
      const response = await fetch(`${api}chat/${ticketId}/messages`);
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched messages:', data);
        setMessages(data);
      } else {
        console.error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateTicketStatus = async () => {
    setLoading(true);
    console.log(`Updating ticket status for ticket ${ticketId}...`);
    try {
      await fetch(`${api}ticket/${ticketId}/chatStatus`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ chatStatus: 'Read by Heimwood' })
      });
      console.log('Ticket status updated successfully.');
    } catch (error) {
      console.error('Error updating ticket status:', error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    setLoading(true);
    console.log(`Sending message for ticket ${ticketId}...`);
    try {
      const response = await fetch(`${api}support-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ticketId, content: newMessage })
      });

      if (response.ok) {
        console.log('Message sent successfully.');
        setNewMessage('');
        fetchMessages();
      } else {
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error('Failed to send message', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Component mounted. Starting to fetch messages...');
    fetchMessages();

    const intervalId = setInterval(() => {
      console.log('Refreshing messages...');
      fetchMessages();
    }, 20000);

    return () => {
      console.log('Component unmounted. Clearing message refresh interval.');
      clearInterval(intervalId);
    };
  }, [ticketId]);

  useEffect(() => {
    console.log('Messages updated:', messages);
    if (messages.length > 0 && messages[messages.length - 1].sender !== 'support') {
      console.log('Last message is not from support. Updating ticket status...');
      updateTicketStatus();
    }
  }, [messages]);

  return (
    <ChatContainer>
      <ChatTitle>Chat</ChatTitle>
      {loading ? (
        <LoadingSpinner /> // Show loading spinner when loading
      ) : (
        <MessagesContainer>
          {messages.map((msg, index) => (
            <Message key={index} sent={msg.sender === 'support' ? 'true' : 'false'}>
              <p><strong>{msg.sender === 'support' ? 'Support' : 'Client'}:</strong> {msg.content}</p>
            </Message>
          ))}
        </MessagesContainer>
      )}
      <InputContainer>
        <MessageInput
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <SendButton onClick={sendMessage}>Send</SendButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default ChatPage;
