import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Input } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const BookingLeads = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hours, setHours] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}book-leads`);
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const formattedData = sortedData.map(lead => ({
          ...lead,
          'createdAtFormatted': moment(lead.createdAt).fromNow(),
        }));
        setLeads(formattedData);
        setFilteredLeads(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, []);

  const getTimeDifferenceInHours = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    return Math.abs(Math.floor((now - createdAtDate) / 3600000)); // 3600000 ms in an hour
  };

  const handleFilterLeads = () => {
    if (hours === '') {
      setFilteredLeads(leads); // If no hours specified, show all leads
    } else {
      const filtered = leads.filter(lead => getTimeDifferenceInHours(lead.createdAt) <= hours);
      setFilteredLeads(filtered);
    }
  };

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'Attendee - First Name',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'Attendee - Last Name',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'Attendee - Email',
      key: 'email',
    },
    {
      title: 'Time Since Creation',
      dataIndex: 'createdAtFormatted',
      key: 'time',
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <div style={{ marginBottom: 16 }}>
            <Button onClick={() => navigate('/')} style={{ marginRight: 8 }}>Client Table</Button>
            <Button onClick={() => navigate('/fa-et-tilbud-leads')} style={{ marginRight: 8 }}>
              Fa et Tilbud Leads
            </Button>
            <Button onClick={() => navigate('/quick-leads')} style={{ marginRight: 8 }}>
              Quick Config Leads
            </Button>
            <Button onClick={() => navigate('/booking-leads')} style={{ marginRight: 8 }}>
              Booking Leads
            </Button>
            <Button onClick={() => navigate('/booking-pictures')}>
              Booking Pictures
            </Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            <span>Total Leads: {filteredLeads.length}</span>
            <div style={{ marginTop: 16 }}>
              <Input 
                type="number" 
                placeholder="Enter hours" 
                value={hours} 
                onChange={handleHoursChange} 
                style={{ width: 200, marginRight: 8 }} 
              />
              <Button onClick={handleFilterLeads}>Filter Leads</Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredLeads}
            rowKey="Attendee - ID"
            pagination={{ pageSize: 10 }}
          />
        </>
      )}
    </div>
  );
};

export default BookingLeads;
