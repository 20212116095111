import React, { useEffect, useState } from 'react';
import { Table, Spin, Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

const QuickLeads = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hours, setHours] = useState('');
  const navigate = useNavigate();

  const itemNames = {
    1: '3 SKUFFER 40 CM',
    2: '3 SKUFFER 60 cm',
    3: '3 SKUFFER 80 cm',
    4: 'OPVASKEFRONT 60 CM',
    5: 'TIL KØL (60 CM)',
    6: 'TIL OVN (60 CM)',
    7: 'M. 3 SKUFFER (60 CM)',
    8: 'DOBBELTSKAB 3 SKUFFER (80 CM)',
    9: 'FORRAMME 3 SKUFFER 40 CM',
    10: 'FORRAMME 3 SKUFFER 60 cm',
    11: 'FORRAMME 3 SKUFFER 80 cm',
    12: 'FORRAMME OPVASKEFRONT 60 CM',
    13: 'FORRAMME TIL KØL (60 CM)',
    14: 'FORRAMME TIL OVN (60 CM)',
    15: 'FORRAMME M. 3 SKUFFER (60 CM)',
    16: 'FORRAMME DOBBELTSKAB 3 SKUFFER (80 CM)',
    17: 'SHAKER 3 SKUFFER 40 CM',
    18: 'SHAKER 3 SKUFFER 60 cm',
    19: 'SHAKER 3 SKUFFER 80 cm',
    20: 'SHAKER OPVASKEFRONT 60 CM',
    21: 'SHAKER TIL KØL (60 CM)',
    22: 'SHAKER TIL OVN (60 CM)',
    23: 'SHAKER M. 3 SKUFFER (60 CM)',
    24: 'SHAKER DOBBELTSKAB 3 SKUFFER (80 CM)'
  };

  const generateCheckboxMessages = (checkboxes) => {
    let message = '';
    if (checkboxes['sampleCheckbox1']) {
      message += 'For Finger-tapped: NATURLIG OLIEBEHANDLING (INKLUDERET I PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox2']) {
      message += 'For Finger-tapped: ANDEN RUBIO FARVE OLIEBEHANDLING (15% OVENI PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox3']) {
      message += 'For Pre-frame: NATURLIG OLIEBEHANDLING (INKLUDERET I PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox4']) {
      message += 'For Pre-frame: ANDEN RUBIO FARVE OLIEBEHANDLING (15% OVENI PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox5']) {
      message += 'For Pre-frame: MALING I VALGFRI JOTUN FARVE (15% OVENI PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox6']) {
      message += 'For Shaker: NATURLIG OLIEBEHANDLING (INKLUDERET I PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox7']) {
      message += 'For Shaker: ANDEN RUBIO FARVE OLIEBEHANDLING (15% OVENI PRISEN)\n';
    }
    if (checkboxes['sampleCheckbox8']) {
      message += 'For Shaker: MALING I VALGFRI JOTUN FARVE (15% OVENI PRISEN)\n';
    }
    return message;
  };

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}quick-leads`);
        const data = await response.json();
        console.log(data);

        const processedData = data.map(lead => {
          const quantities = Object.entries(lead.quantities || {})
            .map(([key, value]) => {
              const itemNumber = key.replace('quantity', '');
              const itemName = itemNames[itemNumber] || 'Unknown Item';
              return `${itemName}: ${value}`;
            });

          const messages = generateCheckboxMessages(lead.checkboxes || {});

          return {
            ...lead,
            quantities,
            messages
          };
        });

        processedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setLeads(processedData);
        setFilteredLeads(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, []);

  const getTimeDifferenceInHours = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    return Math.abs(Math.floor((now - createdAtDate) / 3600000)); // 3600000 ms in an hour
  };

  const handleFilterLeads = () => {
    if (hours === '') {
      setFilteredLeads(leads); // If no hours specified, show all leads
    } else {
      const filtered = leads.filter(lead => getTimeDifferenceInHours(lead.createdAt) <= hours);
      setFilteredLeads(filtered);
    }
  };

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Checkboxes',
      dataIndex: 'messages',
      key: 'messages',
      render: messages => <pre>{messages}</pre>,
    },
    {
      title: 'Quantities',
      dataIndex: 'quantities',
      key: 'quantities',
      render: quantities => <div>{quantities.join(', ')}</div>,
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'time',
      render: createdAt => new Date(createdAt).toLocaleString(),
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div>
          <div style={{ marginBottom: 16 }}>
            <Button onClick={() => navigate('/')} style={{ marginRight: 8 }}>Client Table</Button>
            <Button onClick={navigate.bind(null, '/fa-et-tilbud-leads')} style={{ marginRight: 8 }}>Fa et Tilbud Leads</Button>
            <Button onClick={navigate.bind(null, '/quick-leads')} style={{ marginRight: 8 }}>Quick Config Leads</Button>
            <Button onClick={navigate.bind(null, '/booking-leads')} style={{ marginRight: 8 }}>Booking Leads</Button>
            <Button onClick={navigate.bind(null, '/booking-pictures')}>Booking Pictures</Button>
          </div>
          <div style={{ marginBottom: 16 }}>
            <span>Total Leads: {filteredLeads.length}</span>
            <div style={{ marginTop: 16 }}>
              <Input 
                type="number" 
                placeholder="Enter hours" 
                value={hours} 
                onChange={handleHoursChange} 
                style={{ width: 200, marginRight: 8 }} 
              />
              <Button onClick={handleFilterLeads}>Filter Leads</Button>
            </div>
          </div>
          <Table columns={columns} dataSource={filteredLeads} rowKey={record => record._id} />
        </div>
      )}
    </div>
  );
};

export default QuickLeads;
