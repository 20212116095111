import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import TableComponent from './TableComponent';
import ClientDetailsComponent from './ClientDetailsComponent';
import Leads from './Leads';
import LeadDetail from './LeadDetail'
import BookingLeads from './BookingLeads'
import QuickLeads from './QuickLeads'
import BookingPictures from './BookingPictures'
import NewEmails from "./NewEmails"
import HomePage from "./HomePage"
import ContactLeads from "./ContactLeads"
import CreateMounting from "./CreateMounting"
import ChatPage from "./ChatPage"
import AllTickets from "./AllTickets"
import LoginPage from "./Login"
import MyTickets from './MyTickets';
import Catalogue from './Catalogue';

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    if (!userEmail) {
      // Redirect to login page and save the attempted URL
      navigate('/login', { state: { from: location.pathname } });
    }
  }, [navigate, location]);

  return children;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route path="/" element={
          <ProtectedRoute>
            <TableComponent />
          </ProtectedRoute>
        } />
        <Route path="/clients/:clientId" element={
          <ProtectedRoute>
            <ClientDetailsComponent />
          </ProtectedRoute>
        } />
        <Route path="/fa-et-tilbud-leads" element={
          <ProtectedRoute>
            <Leads />
          </ProtectedRoute>
        } />
        <Route path="/fa-et-tilbud-leads/:id" element={
          <ProtectedRoute>
            <LeadDetail />
          </ProtectedRoute>
        } />
        <Route path="/booking-leads" element={
          <ProtectedRoute>
            <BookingLeads />
          </ProtectedRoute>
        } />
        <Route path="/quick-leads" element={
          <ProtectedRoute>
            <QuickLeads />
          </ProtectedRoute>
        } />
        <Route path="/booking-pictures" element={
          <ProtectedRoute>
            <BookingPictures />
          </ProtectedRoute>
        } />
        <Route path="/new-emails" element={
          <ProtectedRoute>
            <NewEmails />
          </ProtectedRoute>
        } />
        <Route path="/home" element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        } />
        <Route path="/contact-leads" element={
          <ProtectedRoute>
            <ContactLeads />
          </ProtectedRoute>
        } />
        <Route path="/create-mounting" element={
          <ProtectedRoute>
            <CreateMounting />
          </ProtectedRoute>
        } />
        <Route path="/chat/:ticketId" element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        } />
        <Route path="/alltickets" element={
          <ProtectedRoute>
            <AllTickets />
          </ProtectedRoute>
        } />

         <Route path="/my-tickets" element={
          <ProtectedRoute>
            <MyTickets />
          </ProtectedRoute>
        } />

          <Route path="/Catalogue" element={
          <ProtectedRoute>
            <Catalogue />
          </ProtectedRoute>
        } />



        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;