import React, { useEffect, useState } from 'react';
import { Table, Button, message, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [hours, setHours] = useState('');
  const navigate = useNavigate();
  const api = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetchLeads();
  }, []);

  const getTimeDifferenceInHours = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    return Math.abs(Math.floor((now - createdAtDate) / 3600000)); // 3600000 ms in an hour
  };

  const fetchLeads = () => {
    fetch(api + 'leads')
      .then(response => response.json())
      .then(data => {
        // Sort the leads by time difference from createdAt to now, from smallest to largest
        const sortedData = data.sort((a, b) => getTimeDifferenceInHours(a.createdAt) - getTimeDifferenceInHours(b.createdAt));
        setLeads(sortedData);
        setFilteredLeads(sortedData); // Initialize filtered leads with all leads
      })
      .catch(error => console.error('Error fetching leads:', error));
  };

  const handleFilterLeads = () => {
    if (hours === '') {
      setFilteredLeads(leads); // If no hours specified, show all leads
    } else {
      const filtered = leads.filter(lead => getTimeDifferenceInHours(lead.createdAt) <= hours);
      setFilteredLeads(filtered);
    }
  };

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const handleNavigateToLeads = () => {
    navigate('/fa-et-tilbud-leads'); // Navigate to the Leads component
  };

  const handleNavigateToQuickLeads = () => {
    navigate('/quick-leads'); // Navigate to the Leads component
  };

  const handleNavigateToBookingLeads = () => {
    navigate('/booking-leads'); // Navigate to the Leads component
  };

  const handleNavigateToBookingPicturesLeads = () => {
    navigate('/booking-pictures'); // Navigate to the Leads component
  };

  const updateInTheTableStatus = (id) => {
    const updatedStatus = 'yes';
    fetch(api + `leads/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ inTheTable: updatedStatus }),
    })
      .then(response => response.json())
      .then(() => {
        fetchLeads(); // Re-fetch leads to update the UI
        message.success('Added to Clients');
      })
      .catch(error => console.error('Error updating lead:', error));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Fornavn',
      key: 'Fornavn',
      render: (text, record) => `${record.Fornavn} ${record.Efternavn}`,
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
    },
    {
      title: 'In the Table',
      dataIndex: 'inTheTable',
      key: 'inTheTable',
      render: (_, record) => (
        <Button onClick={() => updateInTheTableStatus(record._id)}>
          Add to Clients
        </Button>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'time',
      render: createdAt => getTimeDifferenceInHours(createdAt) + " hours ago",
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => navigate(`/fa-et-tilbud-leads/${record._id}`)}>More</Button>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={() => navigate('/')} style={{ marginRight: 8 }}>Client Table</Button>
        <Button onClick={handleNavigateToLeads} style={{ marginRight: 8 }}>Fa et Tilbud Leads</Button>
        <Button onClick={handleNavigateToQuickLeads} style={{ marginRight: 8 }}>Quick Config Leads</Button>
        <Button onClick={handleNavigateToBookingLeads} style={{ marginRight: 8 }}>Booking Leads</Button>
        <Button onClick={handleNavigateToBookingPicturesLeads}>Booking Pictures</Button>
      </div>
      <div style={{ marginBottom: 16 }}>
        <span>Total Leads: {filteredLeads.length}</span>
        <div style={{ marginTop: 16 }}>
          <Input 
            type="number" 
            placeholder="Enter hours" 
            value={hours} 
            onChange={handleHoursChange} 
            style={{ width: 200, marginRight: 8 }} 
          />
          <Button onClick={handleFilterLeads}>Filter Leads</Button>
        </div>
      </div>
      <Table columns={columns} dataSource={filteredLeads} rowKey={record => record._id} />
    </div>
  );
};

export default Leads;
