import React, { useState, useEffect } from "react";
import axios from "axios";

const api = process.env.REACT_APP_BACKEND_URL;

const Catalogue = () => {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [catalogues, setCatalogues] = useState([]);

  // Fetch all catalogues from the backend
  useEffect(() => {
    const fetchCatalogues = async () => {
      try {
        const response = await axios.get(`${api}/catalogue/get`);
        setCatalogues(response.data.catalogues);
      } catch (error) {
        console.error("Error fetching catalogues", error);
      }
    };
    fetchCatalogues();
  }, []);

  // Handle file upload and URL generation
  const handleFileUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${api}/upload/upload-file`, formData);
      setFileUrl(response.data.url);
      alert("File uploaded successfully! URL generated.");
    } catch (error) {
      alert("File upload failed.");
      console.error(error);
    }
  };

  // Handle saving the catalogue entry
  const handleSaveCatalogue = async (e) => {
    e.preventDefault();

    if (!fileUrl || !description) {
      alert("Both file URL and description are required.");
      return;
    }

    try {
      await axios.post(`${api}/catalogue/create`, {
        url: fileUrl,
        description,
      });
      alert("Catalogue saved successfully!");

      // Fetch updated catalogues
      const response = await axios.get(`${api}/catalogue/get`);
      setCatalogues(response.data.catalogues);

      // Reset the form
      setDescription("");
      setFileUrl("");
      setFile(null);
    } catch (error) {
      alert("Error saving catalogue.");
      console.error(error);
    }
  };

  return (
    <div>
      <h1>Upload Excel Catalogue</h1>
      <form onSubmit={handleFileUpload}>
        <label>
          Select Excel file:
          <input
            type="file"
            accept=".xls,.xlsx"
            onChange={(e) => setFile(e.target.files[0])}
            required
          />
        </label>
        <br /><br />
        <button type="submit">Generate URL</button>
      </form>

      {fileUrl && (
        <form onSubmit={handleSaveCatalogue}>
          <h2>Save Catalogue</h2>
          <label>
            Description:
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </label>
          <br /><br />
          <label>
            Generated File URL:
            <input type="text" value={fileUrl} readOnly required />
          </label>
          <br /><br />
          <button type="submit">Save Catalogue</button>
        </form>
      )}

      <h2>Saved Catalogues</h2>
      {catalogues.length > 0 ? (
        catalogues.map((catalogue) => (
          <div key={catalogue._id}>
            <p><strong>Description:</strong> {catalogue.description}</p>
            <p>
              <strong>URL:</strong> <a href={catalogue.url} target="_blank" rel="noopener noreferrer">{catalogue.url}</a>
            </p>
            <p><strong>Date:</strong> {new Date(catalogue.date).toLocaleString()}</p>
            <hr />
          </div>
        ))
      ) : (
        <p>No catalogues found.</p>
      )}
    </div>
  );
};

export default Catalogue;
