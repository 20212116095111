import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { FaUpload, FaTrash, FaEdit, FaSave, FaTimes, FaFileContract, FaBoxOpen, FaPlus, FaMinus, FaUser, FaSpinner, FaCheckCircle } from 'react-icons/fa';
import * as XLSX from 'xlsx';  // Import the xlsx library

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  color: #333;
  animation: ${fadeIn} 0.5s ease-in;
`;

const Title = styled.h2`
  color: #2c3e50;
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #2980b9;
  }
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #27ae60;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const ContractList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ContractItem = styled.li`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ContractLink = styled.a`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const DeleteButton = styled(Button)`
  background-color: #e74c3c;
  color: white;

  &:hover {
    background-color: #c0392b;
  }
`;

const EditButton = styled(Button)`
  background-color: #f39c12;
  color: white;

  &:hover {
    background-color: #d35400;
  }
`;

const SaveButton = styled(Button)`
  background-color: #2ecc71;
  color: white;

  &:hover {
    background-color: #27ae60;
  }
`;

const CancelButton = styled(Button)`
  background-color: #95a5a6;
  color: white;

  &:hover {
    background-color: #7f8c8d;
  }
`;

const DateInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  margin-right: 0.5rem;
`;

const MounterSelect = styled.select`
  padding: 0.5rem;
  margin-left: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const AdditionalDaysList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
`;

const AdditionalDayItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const AddDayButton = styled(Button)`
  background-color: #27ae60;
  color: white;

  &:hover {
    background-color: #2ecc71;
  }
`;

const RemoveDayButton = styled(Button)`
  background-color: #e74c3c;
  color: white;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;

  &:hover {
    background-color: #c0392b;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  margin-left: 0.5rem;
  animation: spin 1s infinite linear;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SuccessMessage = styled.p`
  color: #2ecc71;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const PdfUploadComponent = ({ userEmail }) => {
  const [link, setLink] = useState('');
  const [contracts, setContracts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [contractValue, setContractValue] = useState(''); 
  const [editingDate, setEditingDate] = useState(null);
  const [newMountingDate, setNewMountingDate] = useState('');
  const [newAdditionalDay, setNewAdditionalDay] = useState('');
  const [selectedMounter, setSelectedMounter] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const api = process.env.REACT_APP_BACKEND_URL;

  const mounters = ['Oscar', 'Frederik', 'Marko', 'Ivan', 'Ivor'];

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    try {
      const response = await axios.get(api + `/usercontract/${userEmail}`);
      setContracts(response.data);
    } catch (error) {
      console.error('Error fetching contracts:', error);
    }
  };

  const handleFileUpload = (event, contractId) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        const lines = contents.split('\n');
        const filteredLines = lines.filter(line => {
          const elements = line.split(',');
          if (elements.length >= 2) {
            const secondElement = elements[0].trim();
            return allowedValues.some(value => secondElement.includes(value));
          }
          return false;
        });
        setCsvData(filteredLines);
      };
      reader.readAsText(file, 'ISO-8859-1');
    }
  };

  const handleDelete = async (contractId) => {
    try {
      await axios.delete(api + `/usercontract/${userEmail}/${contractId}`);
      fetchContracts();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

 const allowedValues = [
    'Sektion vaskeskab m/1 skuffe (blendet) og 2 kolonialskuffer (sammensat) 60 cm',
    'Sektion vaskeskab m/1 skuffe (blendet) og 2 kolonialskuffer (sammensat) 80 cm',
    'Opvaskefront (sammensat) 60 cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 30cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 40cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 50cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 60cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 70cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 80cm',
    'Sektion m/ 1 skuffe 2 kolonialskuffer 90cm',
    'Sektion m/1 skuffe og 2 kolonialskuffer (sammensat front) 30 cm',
    'Sektion m/1 skuffe og 2 kolonialskuffer (sammensat front) 40 cm',
    'Sektion induktion m/ 1 skuffe (blendet) 2 kolonialskuffer 60cm',
    'Sektion induktion m/ 1 skuffe (blendet) 2 kolonialskuffer 80cm',
    'Sektion induktion m/ 1 skuffe (blendet) 2 kolonialskuffer 90cm',
    'Sektion m/ ovn og 1 skuffe 60cm',
    'Indbygningsskab til ovn m/ 1 skuffe 2 kolonialskuffer 60cm',
    'Fingertappede',
    'vaskeskab 581',
    'Vaskeskab 781',
    '32 model 281',
    '32 model 381',
    '32 model 481',
    '32 model 581',
    '32 model 681',
    '32 model 781',
    '32 model 881',
    'Olie eddike skab',
    'induktion 581',
    'induktion 781',
    'induktion 881',
    'skuffe under ovn',
    '32 model under ovn',
  ];
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setSuccess(false); // Reset success message
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setSuccess(false); // Reset success message
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (!contractValue) {
      alert('Please enter the contract value.');
      return;
    }

    if (!selectedFile) {
      alert('Please choose a PDF file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      setLoading(true);
      const response = await axios.post(`${api}/upload/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const contract = {
        userEmail,
        link: response.data.url,
        money: parseFloat(contractValue), // Ensure the value is a float
      };
   const createResponse = await axios.post(`${api}/usercontract/upload-contract`, contract, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const createdContractId = createResponse.data._id; // Get the created contract ID

    // Immediately send the contract ID to the tickets endpoint
    await createContractTickets(createdContractId);

      setSelectedFile(null);
      setContractValue(''); // Clear the contract value after submission
      fetchContracts();
      setSuccess(true); // Show success message
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleChangeMountingDate = (contractId) => {
    setEditingDate(contractId);
    const contract = contracts.find(c => c._id === contractId);
    setNewMountingDate(contract.mountingDate || '');
  };

  const handleSaveMountingDate = async (contractId) => {
    try {
      await axios.put(`${api}/usercontract/${userEmail}/${contractId}`, {
        mountingDate: newMountingDate
      });
      setEditingDate(null);
      fetchContracts();

      //await axios.post(`${api}notify-client`, {
       // receivingEmail: userEmail,
        //subject: 'Mounting Date Changed',
        //text: `<p>The mounting date for your contract with ID ${contractId} has been changed to ${newMountingDate}.</p>`
      //});

    } catch (error) {
      console.error('Error updating mounting date:', error);
    }
  };

  const handleAddAdditionalDay = async (contractId) => {
    if (!newAdditionalDay) return;

    try {
      await axios.post(`${api}/usercontract/${contractId}/append-mounting-day`, {
        mountingDay: newAdditionalDay
      });
      setNewAdditionalDay('');
      fetchContracts();

     // await axios.post(`${api}notify-client`, {
       // receivingEmail: userEmail,
        //subject: 'Additional Mounting Day Added',
        //text: `<p>An additional mounting day ${newAdditionalDay} has been added to your contract with ID ${contractId}.</p>`
      //});

    } catch (error) {
      console.error('Error adding additional mounting day:', error);
    }
  };

  const handleRemoveAdditionalDay = async (contractId, dayToRemove) => {
    try {
      await axios.delete(`${api}/usercontract/${contractId}/delete-mounting-day`, {
        data: { mountingDay: dayToRemove }
      });
      fetchContracts();

      //await axios.post(`${api}notify-client`, {
        //receivingEmail: userEmail,
        //subject: 'Additional Mounting Day Removed',
        //text: `<p>An additional mounting day ${dayToRemove} has been removed from your contract with ID ${contractId}.</p>`
      //});

    } catch (error) {
      console.error('Error removing additional mounting day:', error);
    }
  };

  const handleAddMounter = async (contractId) => {
    if (selectedMounter) {
      try {
        await axios.put(`${api}/usercontract/${userEmail}/${contractId}/mounters`, {
          mounter: selectedMounter,
          action: 'add',
        });
        fetchContracts();
        setSelectedMounter('');
      } catch (error) {
        console.error('Error adding mounter:', error);
      }
    }
  };

  const handleRemoveMounter = async (contractId, mounter) => {
    try {
      await axios.put(`${api}/usercontract/${userEmail}/${contractId}/mounters`, {
        mounter,
        action: 'remove',
      });
      fetchContracts();
    } catch (error) {
      console.error('Error removing mounter:', error);
    }
  };
const handleExcelUpload = (event, contractId) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Get sheet names
      const sheetNames = workbook.SheetNames;

      // Read Sheet 1
      const worksheet1 = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], { header: 1 });

      // Read Sheet 2
      const worksheet2 = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[1]], { header: 1 });

      // Read Sheet 3 ("inserts" sheet for cutleries)
      const worksheet3 = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[2]], { header: 1 });

      // Filter rows from Sheet 1
      const nonEmptyRows = worksheet1.filter((row, index) => 
        index !== 0 && // Exclude header row
        row.length > 9 &&
        row[0] && row[0].toString().trim() !== '' &&
        row[9] && row[9].toString().trim() !== ''
      );

      const orders = [];
      const cutleries = []; // Initialize the cutleries array

      // Filter rows from Sheet 3 where the first column is not null
      worksheet3.forEach(row => {
        if (row[0] && row[0].toString().trim() !== '') {
          cutleries.push(row); // Add the row to cutleries if first column is not null
        }
      });

      // Iterate over filtered rows from Sheet 1
      nonEmptyRows.forEach(row => {
        // Get list of numbers from the 10th column (index 9)
        const numbersList = row[9].toString();
        const numbersArray = numbersList.split(',').map(num => num.trim());

        // Iterate over each number in the list
        numbersArray.forEach(number => {
          // Find matching rows in Sheet 2 where the second column equals the number
          const matchingRows = worksheet2.filter((sheet2Row, index) => 
            index !== 0 && // Exclude header row
            sheet2Row.length > 1 &&
            sheet2Row[1] && sheet2Row[1].toString().trim() === number
          );

          matchingRows.forEach(match => {
            orders.push(match);
            console.log(`Matching row in Sheet 2 for number ${number}:`, match);
          });
        });

        // Set loading state
        setLoading(true);

        // Send orders and cutleries to server
        axios.post(`${api}/drawers/process-drawers`, { orders, cutleries, email: userEmail, contractId })
          .then(response => {
            console.log('Successfully sent orders to /process-drawers:', response.data);
            // Refetch contracts upon success
            fetchContracts();
          })
          .catch(error => {
            console.error('Error sending orders to /process-drawers:', error);
            alert('An error occurred while processing the orders.');
          })
          .finally(() => {
            // Reset loading state
            setLoading(false);
          });
      });
    };
    reader.readAsArrayBuffer(file);
  }
};

const createContractTickets = async (contractId) => {
  const api = process.env.REACT_APP_BACKEND_URL; // Ensure this is correctly set up
  try {
    const response = await axios.post(`${api}/tickets/create-contract-tickets`, {
      contractId: contractId, // Send the created contract ID
    });
    console.log('Contract ID sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending contract ID to /tickets/create-contract-tickets:', error);
  }
};



 
  return (
  <Container>
    {/* Title and file upload section */}
     <Title>Contract Management</Title>
    <Form onSubmit={handleFileSubmit}>
      <FileInput type="file" accept="application/pdf" onChange={handleFileChange} id="file-upload" />
      <FileInputLabel htmlFor="file-upload">
        <FaUpload /> Choose PDF
      </FileInputLabel>
      {selectedFile && (
        <div>
          <p>Selected File: {selectedFile.name}</p>
          <Button onClick={handleFileRemove}>
            <FaTimes /> Remove File
          </Button>
        </div>
      )}
      <Input
        type="number"
        placeholder="Enter contract value in DKK"
        value={contractValue}
        onChange={(e) => setContractValue(e.target.value)}
      />
      <SubmitButton type="submit" disabled={loading}>
        <FaFileContract /> Upload Contract
        {loading && <LoadingSpinner />}
      </SubmitButton>
      {success && (
        <SuccessMessage>
          <FaCheckCircle /> Contract uploaded successfully!
        </SuccessMessage>
      )}
    </Form> 
    
    <Title>Uploaded Contracts</Title>
    <ContractList>
      {contracts.map(contract => (
        <ContractItem key={contract._id}>
          <ContractLink href={contract.link} target="_blank" rel="noopener noreferrer">
            {contract.link}
          </ContractLink>
          <p>Date Created: {new Date(contract.dateOfCreation).toLocaleString()}</p>
          <p><strong>Contract Value:</strong> {contract.money} DKK</p>

          {/* Check if drawersOrdered is true and render a checkmark */}
          {contract.drawersOrdered && (
            <div>
              <FaCheckCircle style={{ color: 'green' }} /> Drawers Ordered
            </div>
          )}

          {/* Commented out drawers order section */}
           <div>
            <FileInputLabel htmlFor={`file-upload-${contract._id}`}>
              <FaBoxOpen /> Order Drawers
            </FileInputLabel>
            <FileInput
              type="file"
              accept=".xlsx, .xls"
              id={`file-upload-${contract._id}`}
              onChange={(e) => handleExcelUpload(e, contract._id)}
            />
            {loading && <LoadingSpinner />}
          </div> 

          {/* Commented out mounting date section */}
          {/* <div>
            <strong>Mounting Date: </strong>
            {editingDate === contract._id ? (
              <>
                <DateInput
                  type="datetime-local"
                  value={newMountingDate}
                  onChange={(e) => setNewMountingDate(e.target.value)}
                />
                <SaveButton onClick={() => handleSaveMountingDate(contract._id)}>
                  <FaSave /> Save
                </SaveButton>
                <CancelButton onClick={() => setEditingDate(null)}>
                  <FaTimes /> Cancel
                </CancelButton>
              </>
            ) : (
              <>
                {contract.mountingDate || 'Not set'}
                <EditButton onClick={() => handleChangeMountingDate(contract._id)}>
                  <FaEdit /> Change
                </EditButton>
              </>
            )}
          </div> */}

          {/* Commented out additional mounting days section */}
          {/* <div>
            <strong>Additional Mounting Days:</strong>
            <AdditionalDaysList>
              {contract.additionalMountingDays && contract.additionalMountingDays.map((day, index) => (
                <AdditionalDayItem key={index}>
                  {day}
                  <RemoveDayButton onClick={() => handleRemoveAdditionalDay(contract._id, day)}>
                    <FaMinus />
                  </RemoveDayButton>
                </AdditionalDayItem>
              ))}
            </AdditionalDaysList>
            <DateInput
              type="datetime-local"
              value={newAdditionalDay}
              onChange={(e) => setNewAdditionalDay(e.target.value)}
            />
            <AddDayButton onClick={() => handleAddAdditionalDay(contract._id)}>
              <FaPlus /> Add Day
            </AddDayButton>
          </div> */}

          {/* Commented out mounters section */}
          {/* <div>
            <strong>Mounters:</strong>
            <ul>
              {contract.mounters && contract.mounters.map((mounter, index) => (
                <li key={index}>
                  {mounter}
                  <RemoveDayButton onClick={() => handleRemoveMounter(contract._id, mounter)}>
                    <FaMinus />
                  </RemoveDayButton>
                </li>
              ))}
            </ul>
            <MounterSelect value={selectedMounter} onChange={(e) => setSelectedMounter(e.target.value)}>
              <option value="">Select Mounter</option>
              {mounters.map(mounter => (
                <option key={mounter} value={mounter}>{mounter}</option>
              ))}
            </MounterSelect>
            <AddDayButton onClick={() => handleAddMounter(contract._id)}>
              <FaPlus /> Add Mounter
            </AddDayButton>
          </div> */}

          {/* Commented out delete button section */}
          <DeleteButton onClick={() => handleDelete(contract._id)}>
            <FaTrash /> Delete
          </DeleteButton> 
        </ContractItem>
      ))}
    </ContractList>
  </Container>
);

};

export default PdfUploadComponent;
