import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Installations = ({ email }) => {
  const [installations, setInstallations] = useState([]);
  const [error, setError] = useState('');
  const api = process.env.REACT_APP_BACKEND_URL;

  const fetchInstallations = async () => {
    try {
      const response = await axios.get(api + 'installations', { params: { email } });
      setInstallations(response.data);
    } catch (error) {
      setError('Error fetching installations');
    }
  };

  useEffect(() => {
    if (email) {
      fetchInstallations();
    }
  }, [email]);

  return (
    <div>
      <h1>Installations</h1>
      {error && <p>{error}</p>}
      <ul>
        {installations.map(installation => (
          <li key={installation._id}>
            <p>Email: {installation.all_emails}</p>
            <p>Address: {installation.client_address}</p>
            <p>Description: {installation.description}</p>
            <p>Event Begins: {installation.eventbegins}</p>
            <p>Status: {installation.status}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Installations;
