import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { FaGoogle } from 'react-icons/fa';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
  }
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f2f5;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f0f2f5;
  padding: 20px;
  animation: ${fadeIn} 0.8s ease-out;
`;

export const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 400px;
  animation: ${slideUp} 0.8s ease-out;
  overflow: hidden;
`;

export const FormContainer = styled.div`
  padding: 40px;
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  color: #1877f2;
  font-size: 24px;
  font-weight: 700;
`;

export const GoogleButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #fff;
  color: #757575;
  border: 1px solid #dadce0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background: #f8f9fa;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const GoogleIcon = styled(FaGoogle)`
  margin-right: 10px;
  color: #4285f4;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1877f2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
